@import '../../../scss/theme/base/variables';
@import "~bootstrap/scss/mixins";

.search_box_container {

  .search_box {
    width: 15rem;
    transition: background-color .15s ease-in;
    background-color: transparent;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: map_get($spacers, 1);
    padding-right: map_get($spacers, 1);

    &.active {
      transition: background-color .15s ease-out;
      background-color: $gray-100;
    }

    .search {
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      width: 100%;
      position: relative;
      display: flex;

      border: $input-border-width solid $input-border-color;
      border-radius: $input-border-radius;
      overflow: hidden;
      color: $input-color;

      .searchTerm {
        width: 100%;
        padding: 5px;
        outline: none;
        border: 0;
      }

      .searchButton {
        width: 2rem;
        background: $white;
        text-align: center;
        cursor: default;
        font-size: 1rem;
        align-self: stretch;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      &.focussed {
        color: $input-focus-color;
        border-color: $input-focus-border-color;
        @if $enable-shadows {
          @include box-shadow($input-box-shadow, $input-focus-box-shadow);
        } @else {
          box-shadow: $input-box-shadow;
        }
      }

    }

    .search_box_results {
      position: absolute;
      background: $black;
      width: 300px;
    }

    .search_box-result-container {
      left: 0;
      position: absolute;
      width: 350px;
      color: $black;
      z-index: 100;
      box-shadow: 0 6px 8px rgba(0, 0, 0, .15), 0 3px 4px rgba(0, 0, 0, .1);
      border-radius: 0 $border-radius-lg $border-radius-lg $border-radius-lg;
      top: 100%;
      overflow: hidden;
    }


    .search_box-results-container {
      height: 15rem;
      overflow-y: auto;
      background-color: white;
      padding-top: 8px;
    }

    .search-results {
      list-style: none;
      display: block;
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        word-wrap: break-word;
        overflow: hidden;
      }

      li a {
        display: block;
        padding: 4px 4px 4px 38px !important;
        color: $gray-900;
        text-decoration: none;
      }

      li a strong {
        display: block;
        padding: 3px 0 0;
        font-weight: 600;
        font-size: 1.1rem;
      }

      li a small {
        display: block;
        padding: 2px 0;
        font-weight: 400;
        font-size: 0.9rem;
      }

      a.active {
        background-color: theme-color-level("primary", -1);
        color: color-yiq(theme-color-level("primary", -1));

        &:hover {
          background-color: theme_color_level("primary", 0);
          color: color-yiq(theme-color-level("primary", 0));
        }
      }

      li a .result-icon {
        position: absolute;
        left: 7px;
        top: 4px;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        font-size: 24px;
      }

      .search-message {
        padding: 10px;
        color: $gray-900;
        font-weight: 400;
        font-size: 1.1rem;
      }
    }

    .search-filters-button {
      position: relative;
      display: table-cell;
      height: 30px;
      width: 48px;
      font-weight: 400;
      font-size: 1rem;
      color: rgba(38, 41, 44, .64);
      padding-top: 10px;
      text-align: center;
      text-decoration: none;
    }

    .search-filters {
      padding-top: 0;
      border-bottom: 1px solid $gray-300;
      height: 40px;
      display: block;
      padding-inline-start: 0;
      background-color: $gray-100;

      .search-filters-filter {
        transition: $transition-base;
        display: block;
        float: left;
        height: 40px;
        min-width: 48px;
        padding: 0 5px;

        &.active {
          a {
            color: $gray-900;
          }

          border-bottom: 6px solid $secondary;

          &:hover {
            border-bottom-width: 7px;
            background-color: $gray-200;
          }
        }

        a {
          transition: $transition-base;
          font-weight: 600;
          font-size: 1.2rem;
          text-decoration: none;
        }
      }

      .search-filters-button-text {
        font-weight: 600;
        font-size: 1.4rem;
      }
    }

    .customFade {
      transition-property: opacity;
      transition-duration: .15s;
      transition-timing-function: ease-in-out;
      opacity: 0;

      &.customShow {
        opacity: 1;
      }
    }
  }

  .organisation-name {
    pointer-events: none;
    float: right;
    margin: .75rem .25rem;
  }
}