@charset "UTF-8";
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #124e96;
  --secondary: #d55e96;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #e78100;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --primary-dark: #173563;
  --default: #FFF;
  --add: #21A63D;
  --delete: #D4667A;
  --nav: #6c6b6b;
  --measurement-existing: #5cb85c;
  --installation-existing: #ff5c22;
  --installation: #e78100;
  --gray: #343a40;
  --gray-400: #ced4da;
  --factory-lab: rgb(159, 12, 180);
  --excel: #1d6f42;
  --admin: #FFFF00;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #f8f9fa;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.75rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #124e96;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0a2a52;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.125rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.75rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}
@media (max-width: 1200px) {
  h1, .h1 {
    font-size: calc(1.375rem + 1.5vw);
  }
}

h2, .h2 {
  font-size: 2rem;
}
@media (max-width: 1200px) {
  h2, .h2 {
    font-size: calc(1.325rem + 0.9vw);
  }
}

h3, .h3 {
  font-size: 1.75rem;
}
@media (max-width: 1200px) {
  h3, .h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
}

h4, .h4 {
  font-size: 1.5rem;
}
@media (max-width: 1200px) {
  h4, .h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.725rem + 5.7vw);
  }
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.675rem + 5.1vw);
  }
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.575rem + 3.9vw);
  }
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
  }
}

hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail, .img-avatar-lg, .img-avatar {
  padding: 0.25rem;
  background-color: #white;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.75rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  box-shadow: none;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row, .craue_formflow_buttons, .card .card-body .card-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1.5rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bdcde2;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #84a3c8;
}

.table-hover .table-primary:hover {
  background-color: #abc0da;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #abc0da;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f3d2e2;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #e9abc8;
}

.table-hover .table-secondary:hover {
  background-color: #eebed5;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #eebed5;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f8dcb8;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f3bd7a;
}

.table-hover .table-warning:hover {
  background-color: #f6d1a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f6d1a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-primary-dark,
.table-primary-dark > th,
.table-primary-dark > td {
  background-color: #bec6d3;
}
.table-primary-dark th,
.table-primary-dark td,
.table-primary-dark thead th,
.table-primary-dark tbody + tbody {
  border-color: #8696ae;
}

.table-hover .table-primary-dark:hover {
  background-color: #afb9c9;
}
.table-hover .table-primary-dark:hover > td,
.table-hover .table-primary-dark:hover > th {
  background-color: #afb9c9;
}

.table-default,
.table-default > th,
.table-default > td {
  background-color: white;
}
.table-default th,
.table-default td,
.table-default thead th,
.table-default tbody + tbody {
  border-color: white;
}

.table-hover .table-default:hover {
  background-color: #f2f2f2;
}
.table-hover .table-default:hover > td,
.table-hover .table-default:hover > th {
  background-color: #f2f2f2;
}

.table-add,
.table-add > th,
.table-add > td {
  background-color: #c1e6c9;
}
.table-add th,
.table-add td,
.table-add thead th,
.table-add tbody + tbody {
  border-color: #8cd19a;
}

.table-hover .table-add:hover {
  background-color: #afdfb9;
}
.table-hover .table-add:hover > td,
.table-hover .table-add:hover > th {
  background-color: #afdfb9;
}

.table-delete,
.table-delete > th,
.table-delete > td {
  background-color: #f3d4da;
}
.table-delete th,
.table-delete td,
.table-delete thead th,
.table-delete tbody + tbody {
  border-color: #e9afba;
}

.table-hover .table-delete:hover {
  background-color: #edc0c9;
}
.table-hover .table-delete:hover > td,
.table-hover .table-delete:hover > th {
  background-color: #edc0c9;
}

.table-nav,
.table-nav > th,
.table-nav > td {
  background-color: #d6d6d6;
}
.table-nav th,
.table-nav td,
.table-nav thead th,
.table-nav tbody + tbody {
  border-color: #b3b2b2;
}

.table-hover .table-nav:hover {
  background-color: #c9c9c9;
}
.table-hover .table-nav:hover > td,
.table-hover .table-nav:hover > th {
  background-color: #c9c9c9;
}

.table-measurement-existing,
.table-measurement-existing > th,
.table-measurement-existing > td {
  background-color: #d1ebd1;
}
.table-measurement-existing th,
.table-measurement-existing td,
.table-measurement-existing thead th,
.table-measurement-existing tbody + tbody {
  border-color: #aadaaa;
}

.table-hover .table-measurement-existing:hover {
  background-color: #bfe3bf;
}
.table-hover .table-measurement-existing:hover > td,
.table-hover .table-measurement-existing:hover > th {
  background-color: #bfe3bf;
}

.table-installation-existing,
.table-installation-existing > th,
.table-installation-existing > td {
  background-color: #ffd1c1;
}
.table-installation-existing th,
.table-installation-existing td,
.table-installation-existing thead th,
.table-installation-existing tbody + tbody {
  border-color: #ffaa8c;
}

.table-hover .table-installation-existing:hover {
  background-color: #ffbea8;
}
.table-hover .table-installation-existing:hover > td,
.table-hover .table-installation-existing:hover > th {
  background-color: #ffbea8;
}

.table-installation,
.table-installation > th,
.table-installation > td {
  background-color: #f8dcb8;
}
.table-installation th,
.table-installation td,
.table-installation thead th,
.table-installation tbody + tbody {
  border-color: #f3bd7a;
}

.table-hover .table-installation:hover {
  background-color: #f6d1a1;
}
.table-hover .table-installation:hover > td,
.table-hover .table-installation:hover > th {
  background-color: #f6d1a1;
}

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: #c6c8ca;
}
.table-gray th,
.table-gray td,
.table-gray thead th,
.table-gray tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-gray:hover {
  background-color: #b9bbbe;
}
.table-hover .table-gray:hover > td,
.table-hover .table-gray:hover > th {
  background-color: #b9bbbe;
}

.table-gray-400,
.table-gray-400 > th,
.table-gray-400 > td {
  background-color: #f1f3f5;
}
.table-gray-400 th,
.table-gray-400 td,
.table-gray-400 thead th,
.table-gray-400 tbody + tbody {
  border-color: #e6e9ec;
}

.table-hover .table-gray-400:hover {
  background-color: #e2e6ea;
}
.table-hover .table-gray-400:hover > td,
.table-hover .table-gray-400:hover > th {
  background-color: #e2e6ea;
}

.table-factory-lab,
.table-factory-lab > th,
.table-factory-lab > td {
  background-color: #e4bbea;
}
.table-factory-lab th,
.table-factory-lab td,
.table-factory-lab thead th,
.table-factory-lab tbody + tbody {
  border-color: #cd81d8;
}

.table-hover .table-factory-lab:hover {
  background-color: #dca8e4;
}
.table-hover .table-factory-lab:hover > td,
.table-hover .table-factory-lab:hover > th {
  background-color: #dca8e4;
}

.table-excel,
.table-excel > th,
.table-excel > td {
  background-color: #c0d7ca;
}
.table-excel th,
.table-excel td,
.table-excel thead th,
.table-excel tbody + tbody {
  border-color: #89b49d;
}

.table-hover .table-excel:hover {
  background-color: #b0cdbd;
}
.table-hover .table-excel:hover > td,
.table-hover .table-excel:hover > th {
  background-color: #b0cdbd;
}

.table-admin,
.table-admin > th,
.table-admin > td {
  background-color: #ffffb8;
}
.table-admin th,
.table-admin td,
.table-admin thead th,
.table-admin tbody + tbody {
  border-color: #ffff7a;
}

.table-hover .table-admin:hover {
  background-color: #ffff9f;
}
.table-hover .table-admin:hover > td,
.table-hover .table-admin:hover > th {
  background-color: #ffff9f;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #3f8ce8;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(18, 78, 150, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(18, 78, 150, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(18, 78, 150, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #124e96;
  border-color: #124e96;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-primary:hover {
  color: #fff;
  background-color: #0e3c74;
  border-color: #0d3668;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0e3c74;
  border-color: #0d3668;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(54, 105, 166, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #124e96;
  border-color: #124e96;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0d3668;
  border-color: #0b305d;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(54, 105, 166, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #d55e96;
  border-color: #d55e96;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-secondary:hover {
  color: #fff;
  background-color: #cd4082;
  border-color: #ca367c;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #cd4082;
  border-color: #ca367c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(219, 118, 166, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #d55e96;
  border-color: #d55e96;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #ca367c;
  border-color: #c13275;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(219, 118, 166, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #fff;
  background-color: #e78100;
  border-color: #e78100;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-warning:hover {
  color: #fff;
  background-color: #c16c00;
  border-color: #b46500;
}
.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #c16c00;
  border-color: #b46500;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(235, 148, 38, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #fff;
  background-color: #e78100;
  border-color: #e78100;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #b46500;
  border-color: #a75d00;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(235, 148, 38, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-primary-dark {
  color: #fff;
  background-color: #173563;
  border-color: #173563;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-primary-dark:hover {
  color: #fff;
  background-color: #102444;
  border-color: #0d1f3a;
}
.btn-primary-dark:focus, .btn-primary-dark.focus {
  color: #fff;
  background-color: #102444;
  border-color: #0d1f3a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(58, 83, 122, 0.5);
}
.btn-primary-dark.disabled, .btn-primary-dark:disabled {
  color: #fff;
  background-color: #173563;
  border-color: #173563;
}
.btn-primary-dark:not(:disabled):not(.disabled):active, .btn-primary-dark:not(:disabled):not(.disabled).active, .show > .btn-primary-dark.dropdown-toggle {
  color: #fff;
  background-color: #0d1f3a;
  border-color: #0b192f;
}
.btn-primary-dark:not(:disabled):not(.disabled):active:focus, .btn-primary-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(58, 83, 122, 0.5);
}

.btn-default {
  color: #212529;
  background-color: #FFF;
  border-color: #FFF;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-default:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-default:focus, .btn-default.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-default.disabled, .btn-default:disabled {
  color: #212529;
  background-color: #FFF;
  border-color: #FFF;
}
.btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active, .show > .btn-default.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-default:not(:disabled):not(.disabled):active:focus, .btn-default:not(:disabled):not(.disabled).active:focus, .show > .btn-default.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-add {
  color: #fff;
  background-color: #21A63D;
  border-color: #21A63D;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-add:hover {
  color: #fff;
  background-color: #1b8631;
  border-color: #197b2d;
}
.btn-add:focus, .btn-add.focus {
  color: #fff;
  background-color: #1b8631;
  border-color: #197b2d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(66, 179, 90, 0.5);
}
.btn-add.disabled, .btn-add:disabled {
  color: #fff;
  background-color: #21A63D;
  border-color: #21A63D;
}
.btn-add:not(:disabled):not(.disabled):active, .btn-add:not(:disabled):not(.disabled).active, .show > .btn-add.dropdown-toggle {
  color: #fff;
  background-color: #197b2d;
  border-color: #167129;
}
.btn-add:not(:disabled):not(.disabled):active:focus, .btn-add:not(:disabled):not(.disabled).active:focus, .show > .btn-add.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(66, 179, 90, 0.5);
}

.btn-delete {
  color: #fff;
  background-color: #D4667A;
  border-color: #D4667A;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-delete:hover {
  color: #fff;
  background-color: #cc4860;
  border-color: #c93e57;
}
.btn-delete:focus, .btn-delete.focus {
  color: #fff;
  background-color: #cc4860;
  border-color: #c93e57;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(218, 125, 142, 0.5);
}
.btn-delete.disabled, .btn-delete:disabled {
  color: #fff;
  background-color: #D4667A;
  border-color: #D4667A;
}
.btn-delete:not(:disabled):not(.disabled):active, .btn-delete:not(:disabled):not(.disabled).active, .show > .btn-delete.dropdown-toggle {
  color: #fff;
  background-color: #c93e57;
  border-color: #c33750;
}
.btn-delete:not(:disabled):not(.disabled):active:focus, .btn-delete:not(:disabled):not(.disabled).active:focus, .show > .btn-delete.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(218, 125, 142, 0.5);
}

.btn-nav {
  color: #fff;
  background-color: #6c6b6b;
  border-color: #6c6b6b;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-nav:hover {
  color: #fff;
  background-color: #595858;
  border-color: #525252;
}
.btn-nav:focus, .btn-nav.focus {
  color: #fff;
  background-color: #595858;
  border-color: #525252;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(130, 129, 129, 0.5);
}
.btn-nav.disabled, .btn-nav:disabled {
  color: #fff;
  background-color: #6c6b6b;
  border-color: #6c6b6b;
}
.btn-nav:not(:disabled):not(.disabled):active, .btn-nav:not(:disabled):not(.disabled).active, .show > .btn-nav.dropdown-toggle {
  color: #fff;
  background-color: #525252;
  border-color: #4c4b4b;
}
.btn-nav:not(:disabled):not(.disabled):active:focus, .btn-nav:not(:disabled):not(.disabled).active:focus, .show > .btn-nav.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(130, 129, 129, 0.5);
}

.btn-measurement-existing {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-measurement-existing:hover {
  color: #fff;
  background-color: #48a648;
  border-color: #449d44;
}
.btn-measurement-existing:focus, .btn-measurement-existing.focus {
  color: #fff;
  background-color: #48a648;
  border-color: #449d44;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(116, 195, 116, 0.5);
}
.btn-measurement-existing.disabled, .btn-measurement-existing:disabled {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-measurement-existing:not(:disabled):not(.disabled):active, .btn-measurement-existing:not(:disabled):not(.disabled).active, .show > .btn-measurement-existing.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #409440;
}
.btn-measurement-existing:not(:disabled):not(.disabled):active:focus, .btn-measurement-existing:not(:disabled):not(.disabled).active:focus, .show > .btn-measurement-existing.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(116, 195, 116, 0.5);
}

.btn-installation-existing {
  color: #fff;
  background-color: #ff5c22;
  border-color: #ff5c22;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-installation-existing:hover {
  color: #fff;
  background-color: #fb4200;
  border-color: #ee3e00;
}
.btn-installation-existing:focus, .btn-installation-existing.focus {
  color: #fff;
  background-color: #fb4200;
  border-color: #ee3e00;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 116, 67, 0.5);
}
.btn-installation-existing.disabled, .btn-installation-existing:disabled {
  color: #fff;
  background-color: #ff5c22;
  border-color: #ff5c22;
}
.btn-installation-existing:not(:disabled):not(.disabled):active, .btn-installation-existing:not(:disabled):not(.disabled).active, .show > .btn-installation-existing.dropdown-toggle {
  color: #fff;
  background-color: #ee3e00;
  border-color: #e13b00;
}
.btn-installation-existing:not(:disabled):not(.disabled):active:focus, .btn-installation-existing:not(:disabled):not(.disabled).active:focus, .show > .btn-installation-existing.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 116, 67, 0.5);
}

.btn-installation {
  color: #fff;
  background-color: #e78100;
  border-color: #e78100;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-installation:hover {
  color: #fff;
  background-color: #c16c00;
  border-color: #b46500;
}
.btn-installation:focus, .btn-installation.focus {
  color: #fff;
  background-color: #c16c00;
  border-color: #b46500;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(235, 148, 38, 0.5);
}
.btn-installation.disabled, .btn-installation:disabled {
  color: #fff;
  background-color: #e78100;
  border-color: #e78100;
}
.btn-installation:not(:disabled):not(.disabled):active, .btn-installation:not(:disabled):not(.disabled).active, .show > .btn-installation.dropdown-toggle {
  color: #fff;
  background-color: #b46500;
  border-color: #a75d00;
}
.btn-installation:not(:disabled):not(.disabled):active:focus, .btn-installation:not(:disabled):not(.disabled).active:focus, .show > .btn-installation.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(235, 148, 38, 0.5);
}

.btn-gray {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-gray:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-gray:focus, .btn-gray.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-gray.disabled, .btn-gray:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active, .show > .btn-gray.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus, .show > .btn-gray.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-gray-400 {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-gray-400:hover {
  color: #212529;
  background-color: #b8c1ca;
  border-color: #b1bbc4;
}
.btn-gray-400:focus, .btn-gray-400.focus {
  color: #212529;
  background-color: #b8c1ca;
  border-color: #b1bbc4;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(180, 186, 191, 0.5);
}
.btn-gray-400.disabled, .btn-gray-400:disabled {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
}
.btn-gray-400:not(:disabled):not(.disabled):active, .btn-gray-400:not(:disabled):not(.disabled).active, .show > .btn-gray-400.dropdown-toggle {
  color: #212529;
  background-color: #b1bbc4;
  border-color: #aab4bf;
}
.btn-gray-400:not(:disabled):not(.disabled):active:focus, .btn-gray-400:not(:disabled):not(.disabled).active:focus, .show > .btn-gray-400.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(180, 186, 191, 0.5);
}

.btn-factory-lab {
  color: #fff;
  background-color: rgb(159, 12, 180);
  border-color: rgb(159, 12, 180);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-factory-lab:hover {
  color: #fff;
  background-color: #7f0a90;
  border-color: #750984;
}
.btn-factory-lab:focus, .btn-factory-lab.focus {
  color: #fff;
  background-color: #7f0a90;
  border-color: #750984;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(173, 48, 191, 0.5);
}
.btn-factory-lab.disabled, .btn-factory-lab:disabled {
  color: #fff;
  background-color: rgb(159, 12, 180);
  border-color: rgb(159, 12, 180);
}
.btn-factory-lab:not(:disabled):not(.disabled):active, .btn-factory-lab:not(:disabled):not(.disabled).active, .show > .btn-factory-lab.dropdown-toggle {
  color: #fff;
  background-color: #750984;
  border-color: #6a0878;
}
.btn-factory-lab:not(:disabled):not(.disabled):active:focus, .btn-factory-lab:not(:disabled):not(.disabled).active:focus, .show > .btn-factory-lab.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(173, 48, 191, 0.5);
}

.btn-excel {
  color: #fff;
  background-color: #1d6f42;
  border-color: #1d6f42;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-excel:hover {
  color: #fff;
  background-color: #155130;
  border-color: #12472a;
}
.btn-excel:focus, .btn-excel.focus {
  color: #fff;
  background-color: #155130;
  border-color: #12472a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 133, 94, 0.5);
}
.btn-excel.disabled, .btn-excel:disabled {
  color: #fff;
  background-color: #1d6f42;
  border-color: #1d6f42;
}
.btn-excel:not(:disabled):not(.disabled):active, .btn-excel:not(:disabled):not(.disabled).active, .show > .btn-excel.dropdown-toggle {
  color: #fff;
  background-color: #12472a;
  border-color: #103c24;
}
.btn-excel:not(:disabled):not(.disabled):active:focus, .btn-excel:not(:disabled):not(.disabled).active:focus, .show > .btn-excel.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 133, 94, 0.5);
}

.btn-admin {
  color: #212529;
  background-color: #FFFF00;
  border-color: #FFFF00;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-admin:hover {
  color: #212529;
  background-color: #d9d900;
  border-color: #cccc00;
}
.btn-admin:focus, .btn-admin.focus {
  color: #212529;
  background-color: #d9d900;
  border-color: #cccc00;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(222, 222, 6, 0.5);
}
.btn-admin.disabled, .btn-admin:disabled {
  color: #212529;
  background-color: #FFFF00;
  border-color: #FFFF00;
}
.btn-admin:not(:disabled):not(.disabled):active, .btn-admin:not(:disabled):not(.disabled).active, .show > .btn-admin.dropdown-toggle {
  color: #212529;
  background-color: #cccc00;
  border-color: #bfbf00;
}
.btn-admin:not(:disabled):not(.disabled):active:focus, .btn-admin:not(:disabled):not(.disabled).active:focus, .show > .btn-admin.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 222, 6, 0.5);
}

.btn-outline-primary {
  color: #124e96;
  border-color: #124e96;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #124e96;
  border-color: #124e96;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(18, 78, 150, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #124e96;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #124e96;
  border-color: #124e96;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(18, 78, 150, 0.5);
}

.btn-outline-secondary {
  color: #d55e96;
  border-color: #d55e96;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #d55e96;
  border-color: #d55e96;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 94, 150, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #d55e96;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #d55e96;
  border-color: #d55e96;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(213, 94, 150, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #e78100;
  border-color: #e78100;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #e78100;
  border-color: #e78100;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 129, 0, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #e78100;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #e78100;
  border-color: #e78100;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(231, 129, 0, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary-dark {
  color: #173563;
  border-color: #173563;
}
.btn-outline-primary-dark:hover {
  color: #fff;
  background-color: #173563;
  border-color: #173563;
}
.btn-outline-primary-dark:focus, .btn-outline-primary-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 53, 99, 0.5);
}
.btn-outline-primary-dark.disabled, .btn-outline-primary-dark:disabled {
  color: #173563;
  background-color: transparent;
}
.btn-outline-primary-dark:not(:disabled):not(.disabled):active, .btn-outline-primary-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-dark.dropdown-toggle {
  color: #fff;
  background-color: #173563;
  border-color: #173563;
}
.btn-outline-primary-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(23, 53, 99, 0.5);
}

.btn-outline-default {
  color: #FFF;
  border-color: #FFF;
}
.btn-outline-default:hover {
  color: #212529;
  background-color: #FFF;
  border-color: #FFF;
}
.btn-outline-default:focus, .btn-outline-default.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-default.disabled, .btn-outline-default:disabled {
  color: #FFF;
  background-color: transparent;
}
.btn-outline-default:not(:disabled):not(.disabled):active, .btn-outline-default:not(:disabled):not(.disabled).active, .show > .btn-outline-default.dropdown-toggle {
  color: #212529;
  background-color: #FFF;
  border-color: #FFF;
}
.btn-outline-default:not(:disabled):not(.disabled):active:focus, .btn-outline-default:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-default.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-add {
  color: #21A63D;
  border-color: #21A63D;
}
.btn-outline-add:hover {
  color: #fff;
  background-color: #21A63D;
  border-color: #21A63D;
}
.btn-outline-add:focus, .btn-outline-add.focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 166, 61, 0.5);
}
.btn-outline-add.disabled, .btn-outline-add:disabled {
  color: #21A63D;
  background-color: transparent;
}
.btn-outline-add:not(:disabled):not(.disabled):active, .btn-outline-add:not(:disabled):not(.disabled).active, .show > .btn-outline-add.dropdown-toggle {
  color: #fff;
  background-color: #21A63D;
  border-color: #21A63D;
}
.btn-outline-add:not(:disabled):not(.disabled):active:focus, .btn-outline-add:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-add.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(33, 166, 61, 0.5);
}

.btn-outline-delete {
  color: #D4667A;
  border-color: #D4667A;
}
.btn-outline-delete:hover {
  color: #fff;
  background-color: #D4667A;
  border-color: #D4667A;
}
.btn-outline-delete:focus, .btn-outline-delete.focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 102, 122, 0.5);
}
.btn-outline-delete.disabled, .btn-outline-delete:disabled {
  color: #D4667A;
  background-color: transparent;
}
.btn-outline-delete:not(:disabled):not(.disabled):active, .btn-outline-delete:not(:disabled):not(.disabled).active, .show > .btn-outline-delete.dropdown-toggle {
  color: #fff;
  background-color: #D4667A;
  border-color: #D4667A;
}
.btn-outline-delete:not(:disabled):not(.disabled):active:focus, .btn-outline-delete:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-delete.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(212, 102, 122, 0.5);
}

.btn-outline-nav {
  color: #6c6b6b;
  border-color: #6c6b6b;
}
.btn-outline-nav:hover {
  color: #fff;
  background-color: #6c6b6b;
  border-color: #6c6b6b;
}
.btn-outline-nav:focus, .btn-outline-nav.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 107, 107, 0.5);
}
.btn-outline-nav.disabled, .btn-outline-nav:disabled {
  color: #6c6b6b;
  background-color: transparent;
}
.btn-outline-nav:not(:disabled):not(.disabled):active, .btn-outline-nav:not(:disabled):not(.disabled).active, .show > .btn-outline-nav.dropdown-toggle {
  color: #fff;
  background-color: #6c6b6b;
  border-color: #6c6b6b;
}
.btn-outline-nav:not(:disabled):not(.disabled):active:focus, .btn-outline-nav:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-nav.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(108, 107, 107, 0.5);
}

.btn-outline-measurement-existing {
  color: #5cb85c;
  border-color: #5cb85c;
}
.btn-outline-measurement-existing:hover {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-outline-measurement-existing:focus, .btn-outline-measurement-existing.focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.5);
}
.btn-outline-measurement-existing.disabled, .btn-outline-measurement-existing:disabled {
  color: #5cb85c;
  background-color: transparent;
}
.btn-outline-measurement-existing:not(:disabled):not(.disabled):active, .btn-outline-measurement-existing:not(:disabled):not(.disabled).active, .show > .btn-outline-measurement-existing.dropdown-toggle {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-outline-measurement-existing:not(:disabled):not(.disabled):active:focus, .btn-outline-measurement-existing:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-measurement-existing.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(92, 184, 92, 0.5);
}

.btn-outline-installation-existing {
  color: #ff5c22;
  border-color: #ff5c22;
}
.btn-outline-installation-existing:hover {
  color: #fff;
  background-color: #ff5c22;
  border-color: #ff5c22;
}
.btn-outline-installation-existing:focus, .btn-outline-installation-existing.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 92, 34, 0.5);
}
.btn-outline-installation-existing.disabled, .btn-outline-installation-existing:disabled {
  color: #ff5c22;
  background-color: transparent;
}
.btn-outline-installation-existing:not(:disabled):not(.disabled):active, .btn-outline-installation-existing:not(:disabled):not(.disabled).active, .show > .btn-outline-installation-existing.dropdown-toggle {
  color: #fff;
  background-color: #ff5c22;
  border-color: #ff5c22;
}
.btn-outline-installation-existing:not(:disabled):not(.disabled):active:focus, .btn-outline-installation-existing:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-installation-existing.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 92, 34, 0.5);
}

.btn-outline-installation {
  color: #e78100;
  border-color: #e78100;
}
.btn-outline-installation:hover {
  color: #fff;
  background-color: #e78100;
  border-color: #e78100;
}
.btn-outline-installation:focus, .btn-outline-installation.focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 129, 0, 0.5);
}
.btn-outline-installation.disabled, .btn-outline-installation:disabled {
  color: #e78100;
  background-color: transparent;
}
.btn-outline-installation:not(:disabled):not(.disabled):active, .btn-outline-installation:not(:disabled):not(.disabled).active, .show > .btn-outline-installation.dropdown-toggle {
  color: #fff;
  background-color: #e78100;
  border-color: #e78100;
}
.btn-outline-installation:not(:disabled):not(.disabled):active:focus, .btn-outline-installation:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-installation.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(231, 129, 0, 0.5);
}

.btn-outline-gray {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-gray:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-gray:focus, .btn-outline-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-gray.disabled, .btn-outline-gray:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active, .show > .btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-gray:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-gray-400 {
  color: #ced4da;
  border-color: #ced4da;
}
.btn-outline-gray-400:hover {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
}
.btn-outline-gray-400:focus, .btn-outline-gray-400.focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}
.btn-outline-gray-400.disabled, .btn-outline-gray-400:disabled {
  color: #ced4da;
  background-color: transparent;
}
.btn-outline-gray-400:not(:disabled):not(.disabled):active, .btn-outline-gray-400:not(:disabled):not(.disabled).active, .show > .btn-outline-gray-400.dropdown-toggle {
  color: #212529;
  background-color: #ced4da;
  border-color: #ced4da;
}
.btn-outline-gray-400:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-400:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-gray-400.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-outline-factory-lab {
  color: rgb(159, 12, 180);
  border-color: rgb(159, 12, 180);
}
.btn-outline-factory-lab:hover {
  color: #fff;
  background-color: rgb(159, 12, 180);
  border-color: rgb(159, 12, 180);
}
.btn-outline-factory-lab:focus, .btn-outline-factory-lab.focus {
  box-shadow: 0 0 0 0.2rem rgba(159, 12, 180, 0.5);
}
.btn-outline-factory-lab.disabled, .btn-outline-factory-lab:disabled {
  color: rgb(159, 12, 180);
  background-color: transparent;
}
.btn-outline-factory-lab:not(:disabled):not(.disabled):active, .btn-outline-factory-lab:not(:disabled):not(.disabled).active, .show > .btn-outline-factory-lab.dropdown-toggle {
  color: #fff;
  background-color: rgb(159, 12, 180);
  border-color: rgb(159, 12, 180);
}
.btn-outline-factory-lab:not(:disabled):not(.disabled):active:focus, .btn-outline-factory-lab:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-factory-lab.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(159, 12, 180, 0.5);
}

.btn-outline-excel {
  color: #1d6f42;
  border-color: #1d6f42;
}
.btn-outline-excel:hover {
  color: #fff;
  background-color: #1d6f42;
  border-color: #1d6f42;
}
.btn-outline-excel:focus, .btn-outline-excel.focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 111, 66, 0.5);
}
.btn-outline-excel.disabled, .btn-outline-excel:disabled {
  color: #1d6f42;
  background-color: transparent;
}
.btn-outline-excel:not(:disabled):not(.disabled):active, .btn-outline-excel:not(:disabled):not(.disabled).active, .show > .btn-outline-excel.dropdown-toggle {
  color: #fff;
  background-color: #1d6f42;
  border-color: #1d6f42;
}
.btn-outline-excel:not(:disabled):not(.disabled):active:focus, .btn-outline-excel:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-excel.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(29, 111, 66, 0.5);
}

.btn-outline-admin {
  color: #FFFF00;
  border-color: #FFFF00;
}
.btn-outline-admin:hover {
  color: #212529;
  background-color: #FFFF00;
  border-color: #FFFF00;
}
.btn-outline-admin:focus, .btn-outline-admin.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 0, 0.5);
}
.btn-outline-admin.disabled, .btn-outline-admin:disabled {
  color: #FFFF00;
  background-color: transparent;
}
.btn-outline-admin:not(:disabled):not(.disabled):active, .btn-outline-admin:not(:disabled):not(.disabled).active, .show > .btn-outline-admin.dropdown-toggle {
  color: #212529;
  background-color: #FFFF00;
  border-color: #FFFF00;
}
.btn-outline-admin:not(:disabled):not(.disabled):active:focus, .btn-outline-admin:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-admin.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 255, 0, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #124e96;
  text-decoration: none;
}
.btn-link:hover {
  color: #0a2a52;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.75rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.375rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #212529;
  text-decoration: none;
  background-color: #dee2e6;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.375rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #124e96;
  background-color: #124e96;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(18, 78, 150, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #3f8ce8;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #6da7ed;
  border-color: #6da7ed;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #124e96;
  background-color: #124e96;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(18, 78, 150, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(18, 78, 150, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(18, 78, 150, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(18, 78, 150, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  appearance: none;
}
.custom-select:focus {
  border-color: #3f8ce8;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(18, 78, 150, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #3f8ce8;
  box-shadow: 0 0 0 0.2rem rgba(18, 78, 150, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f8f9fa, 0 0 0 0.2rem rgba(18, 78, 150, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f8f9fa, 0 0 0 0.2rem rgba(18, 78, 150, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f8f9fa, 0 0 0 0.2rem rgba(18, 78, 150, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #124e96;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #6da7ed;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #124e96;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #6da7ed;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #124e96;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #6da7ed;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.875rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #f8f9fa;
  border-color: #dee2e6 #dee2e6 #f8f9fa;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #124e96;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.5rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
  margin-right: 1.5rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: #FFF;
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #FFF;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: #FFF;
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23FFF' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: #FFF;
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 0.75rem;
    margin-bottom: 0;
    margin-left: 0.75rem;
  }
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #124e96;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0a2a52;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(18, 78, 150, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #124e96;
  border-color: #124e96;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #124e96;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0d3668;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(18, 78, 150, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #d55e96;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #ca367c;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(213, 94, 150, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #fff;
  background-color: #e78100;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #fff;
  background-color: #b46500;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(231, 129, 0, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.badge-primary-dark {
  color: #fff;
  background-color: #173563;
}
a.badge-primary-dark:hover, a.badge-primary-dark:focus {
  color: #fff;
  background-color: #0d1f3a;
}
a.badge-primary-dark:focus, a.badge-primary-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 53, 99, 0.5);
}

.badge-default {
  color: #212529;
  background-color: #FFF;
}
a.badge-default:hover, a.badge-default:focus {
  color: #212529;
  background-color: #e6e6e6;
}
a.badge-default:focus, a.badge-default.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-add {
  color: #fff;
  background-color: #21A63D;
}
a.badge-add:hover, a.badge-add:focus {
  color: #fff;
  background-color: #197b2d;
}
a.badge-add:focus, a.badge-add.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 166, 61, 0.5);
}

.badge-delete {
  color: #fff;
  background-color: #D4667A;
}
a.badge-delete:hover, a.badge-delete:focus {
  color: #fff;
  background-color: #c93e57;
}
a.badge-delete:focus, a.badge-delete.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(212, 102, 122, 0.5);
}

.badge-nav {
  color: #fff;
  background-color: #6c6b6b;
}
a.badge-nav:hover, a.badge-nav:focus {
  color: #fff;
  background-color: #525252;
}
a.badge-nav:focus, a.badge-nav.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 107, 107, 0.5);
}

.badge-measurement-existing {
  color: #fff;
  background-color: #5cb85c;
}
a.badge-measurement-existing:hover, a.badge-measurement-existing:focus {
  color: #fff;
  background-color: #449d44;
}
a.badge-measurement-existing:focus, a.badge-measurement-existing.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.5);
}

.badge-installation-existing {
  color: #fff;
  background-color: #ff5c22;
}
a.badge-installation-existing:hover, a.badge-installation-existing:focus {
  color: #fff;
  background-color: #ee3e00;
}
a.badge-installation-existing:focus, a.badge-installation-existing.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 92, 34, 0.5);
}

.badge-installation {
  color: #fff;
  background-color: #e78100;
}
a.badge-installation:hover, a.badge-installation:focus {
  color: #fff;
  background-color: #b46500;
}
a.badge-installation:focus, a.badge-installation.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(231, 129, 0, 0.5);
}

.badge-gray {
  color: #fff;
  background-color: #343a40;
}
a.badge-gray:hover, a.badge-gray:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-gray:focus, a.badge-gray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.badge-gray-400 {
  color: #212529;
  background-color: #ced4da;
}
a.badge-gray-400:hover, a.badge-gray-400:focus {
  color: #212529;
  background-color: #b1bbc4;
}
a.badge-gray-400:focus, a.badge-gray-400.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.badge-factory-lab {
  color: #fff;
  background-color: rgb(159, 12, 180);
}
a.badge-factory-lab:hover, a.badge-factory-lab:focus {
  color: #fff;
  background-color: #750984;
}
a.badge-factory-lab:focus, a.badge-factory-lab.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(159, 12, 180, 0.5);
}

.badge-excel {
  color: #fff;
  background-color: #1d6f42;
}
a.badge-excel:hover, a.badge-excel:focus {
  color: #fff;
  background-color: #12472a;
}
a.badge-excel:focus, a.badge-excel.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(29, 111, 66, 0.5);
}

.badge-admin {
  color: #212529;
  background-color: #FFFF00;
}
a.badge-admin:hover, a.badge-admin:focus {
  color: #212529;
  background-color: #cccc00;
}
a.badge-admin:focus, a.badge-admin.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 0, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #09294e;
  background-color: #d0dcea;
  border-color: #bdcde2;
}
.alert-primary hr {
  border-top-color: #abc0da;
}
.alert-primary .alert-link {
  color: #041120;
}

.alert-secondary {
  color: #6f314e;
  background-color: #f7dfea;
  border-color: #f3d2e2;
}
.alert-secondary hr {
  border-top-color: #eebed5;
}
.alert-secondary .alert-link {
  color: #4c2135;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #784300;
  background-color: #fae6cc;
  border-color: #f8dcb8;
}
.alert-warning hr {
  border-top-color: #f6d1a1;
}
.alert-warning .alert-link {
  color: #452700;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

.alert-primary-dark {
  color: #0c1c33;
  background-color: #d1d7e0;
  border-color: #bec6d3;
}
.alert-primary-dark hr {
  border-top-color: #afb9c9;
}
.alert-primary-dark .alert-link {
  color: #02050a;
}

.alert-default {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-default hr {
  border-top-color: #f2f2f2;
}
.alert-default .alert-link {
  color: #6c6c6c;
}

.alert-add {
  color: #115620;
  background-color: #d3edd8;
  border-color: #c1e6c9;
}
.alert-add hr {
  border-top-color: #afdfb9;
}
.alert-add .alert-link {
  color: #092b10;
}

.alert-delete {
  color: #6e353f;
  background-color: #f6e0e4;
  border-color: #f3d4da;
}
.alert-delete hr {
  border-top-color: #edc0c9;
}
.alert-delete .alert-link {
  color: #4c242b;
}

.alert-nav {
  color: #383838;
  background-color: #e2e1e1;
  border-color: #d6d6d6;
}
.alert-nav hr {
  border-top-color: #c9c9c9;
}
.alert-nav .alert-link {
  color: #1f1f1f;
}

.alert-measurement-existing {
  color: #306030;
  background-color: #def1de;
  border-color: #d1ebd1;
}
.alert-measurement-existing hr {
  border-top-color: #bfe3bf;
}
.alert-measurement-existing .alert-link {
  color: #1f3e1f;
}

.alert-installation-existing {
  color: #853012;
  background-color: #ffded3;
  border-color: #ffd1c1;
}
.alert-installation-existing hr {
  border-top-color: #ffbea8;
}
.alert-installation-existing .alert-link {
  color: #58200c;
}

.alert-installation {
  color: #784300;
  background-color: #fae6cc;
  border-color: #f8dcb8;
}
.alert-installation hr {
  border-top-color: #f6d1a1;
}
.alert-installation .alert-link {
  color: #452700;
}

.alert-gray {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-gray hr {
  border-top-color: #b9bbbe;
}
.alert-gray .alert-link {
  color: #040505;
}

.alert-gray-400 {
  color: #6b6e71;
  background-color: #f5f6f8;
  border-color: #f1f3f5;
}
.alert-gray-400 hr {
  border-top-color: #e2e6ea;
}
.alert-gray-400 .alert-link {
  color: #525557;
}

.alert-factory-lab {
  color: #53065e;
  background-color: #eccef0;
  border-color: #e4bbea;
}
.alert-factory-lab hr {
  border-top-color: #dca8e4;
}
.alert-factory-lab .alert-link {
  color: #29032e;
}

.alert-excel {
  color: #0f3a22;
  background-color: #d2e2d9;
  border-color: #c0d7ca;
}
.alert-excel hr {
  border-top-color: #b0cdbd;
}
.alert-excel .alert-link {
  color: #05110a;
}

.alert-admin {
  color: #858500;
  background-color: #ffffcc;
  border-color: #ffffb8;
}
.alert-admin hr {
  border-top-color: #ffff9f;
}
.alert-admin .alert-link {
  color: #525200;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #124e96;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item, .list-group-subitem {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child, .list-group-subitem:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child, .list-group-subitem:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .disabled.list-group-subitem, .list-group-item:disabled, .list-group-subitem:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active, .active.list-group-subitem {
  z-index: 2;
  color: #fff;
  background-color: #124e96;
  border-color: #124e96;
}
.list-group-item + .list-group-item, .list-group-subitem + .list-group-item, .list-group-item + .list-group-subitem, .list-group-subitem + .list-group-subitem {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active, .list-group-subitem + .list-group-item.active, .list-group-item + .active.list-group-subitem, .list-group-subitem + .active.list-group-subitem {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child, .list-group-horizontal > .list-group-subitem:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child, .list-group-horizontal > .list-group-subitem:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active, .list-group-horizontal > .active.list-group-subitem {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item, .list-group-horizontal > .list-group-subitem + .list-group-item, .list-group-horizontal > .list-group-item + .list-group-subitem, .list-group-horizontal > .list-group-subitem + .list-group-subitem {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active, .list-group-horizontal > .list-group-subitem + .list-group-item.active, .list-group-horizontal > .list-group-item + .active.list-group-subitem, .list-group-horizontal > .list-group-subitem + .active.list-group-subitem {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child, .list-group-horizontal-sm > .list-group-subitem:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child, .list-group-horizontal-sm > .list-group-subitem:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active, .list-group-horizontal-sm > .active.list-group-subitem {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item, .list-group-horizontal-sm > .list-group-subitem + .list-group-item, .list-group-horizontal-sm > .list-group-item + .list-group-subitem, .list-group-horizontal-sm > .list-group-subitem + .list-group-subitem {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active, .list-group-horizontal-sm > .list-group-subitem + .list-group-item.active, .list-group-horizontal-sm > .list-group-item + .active.list-group-subitem, .list-group-horizontal-sm > .list-group-subitem + .active.list-group-subitem {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child, .list-group-horizontal-md > .list-group-subitem:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child, .list-group-horizontal-md > .list-group-subitem:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active, .list-group-horizontal-md > .active.list-group-subitem {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item, .list-group-horizontal-md > .list-group-subitem + .list-group-item, .list-group-horizontal-md > .list-group-item + .list-group-subitem, .list-group-horizontal-md > .list-group-subitem + .list-group-subitem {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active, .list-group-horizontal-md > .list-group-subitem + .list-group-item.active, .list-group-horizontal-md > .list-group-item + .active.list-group-subitem, .list-group-horizontal-md > .list-group-subitem + .active.list-group-subitem {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child, .list-group-horizontal-lg > .list-group-subitem:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child, .list-group-horizontal-lg > .list-group-subitem:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active, .list-group-horizontal-lg > .active.list-group-subitem {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item, .list-group-horizontal-lg > .list-group-subitem + .list-group-item, .list-group-horizontal-lg > .list-group-item + .list-group-subitem, .list-group-horizontal-lg > .list-group-subitem + .list-group-subitem {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active, .list-group-horizontal-lg > .list-group-subitem + .list-group-item.active, .list-group-horizontal-lg > .list-group-item + .active.list-group-subitem, .list-group-horizontal-lg > .list-group-subitem + .active.list-group-subitem {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child, .list-group-horizontal-xl > .list-group-subitem:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child, .list-group-horizontal-xl > .list-group-subitem:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active, .list-group-horizontal-xl > .active.list-group-subitem {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item, .list-group-horizontal-xl > .list-group-subitem + .list-group-item, .list-group-horizontal-xl > .list-group-item + .list-group-subitem, .list-group-horizontal-xl > .list-group-subitem + .list-group-subitem {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active, .list-group-horizontal-xl > .list-group-subitem + .list-group-item.active, .list-group-horizontal-xl > .list-group-item + .active.list-group-subitem, .list-group-horizontal-xl > .list-group-subitem + .active.list-group-subitem {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item, .list-group-flush > .list-group-subitem {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child, .list-group-flush > .list-group-subitem:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #09294e;
  background-color: #bdcde2;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #09294e;
  background-color: #abc0da;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #09294e;
  border-color: #09294e;
}

.list-group-item-secondary {
  color: #6f314e;
  background-color: #f3d2e2;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #6f314e;
  background-color: #eebed5;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #6f314e;
  border-color: #6f314e;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #784300;
  background-color: #f8dcb8;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #784300;
  background-color: #f6d1a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #784300;
  border-color: #784300;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.list-group-item-primary-dark {
  color: #0c1c33;
  background-color: #bec6d3;
}
.list-group-item-primary-dark.list-group-item-action:hover, .list-group-item-primary-dark.list-group-item-action:focus {
  color: #0c1c33;
  background-color: #afb9c9;
}
.list-group-item-primary-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0c1c33;
  border-color: #0c1c33;
}

.list-group-item-default {
  color: #858585;
  background-color: white;
}
.list-group-item-default.list-group-item-action:hover, .list-group-item-default.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}
.list-group-item-default.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-add {
  color: #115620;
  background-color: #c1e6c9;
}
.list-group-item-add.list-group-item-action:hover, .list-group-item-add.list-group-item-action:focus {
  color: #115620;
  background-color: #afdfb9;
}
.list-group-item-add.list-group-item-action.active {
  color: #fff;
  background-color: #115620;
  border-color: #115620;
}

.list-group-item-delete {
  color: #6e353f;
  background-color: #f3d4da;
}
.list-group-item-delete.list-group-item-action:hover, .list-group-item-delete.list-group-item-action:focus {
  color: #6e353f;
  background-color: #edc0c9;
}
.list-group-item-delete.list-group-item-action.active {
  color: #fff;
  background-color: #6e353f;
  border-color: #6e353f;
}

.list-group-item-nav {
  color: #383838;
  background-color: #d6d6d6;
}
.list-group-item-nav.list-group-item-action:hover, .list-group-item-nav.list-group-item-action:focus {
  color: #383838;
  background-color: #c9c9c9;
}
.list-group-item-nav.list-group-item-action.active {
  color: #fff;
  background-color: #383838;
  border-color: #383838;
}

.list-group-item-measurement-existing {
  color: #306030;
  background-color: #d1ebd1;
}
.list-group-item-measurement-existing.list-group-item-action:hover, .list-group-item-measurement-existing.list-group-item-action:focus {
  color: #306030;
  background-color: #bfe3bf;
}
.list-group-item-measurement-existing.list-group-item-action.active {
  color: #fff;
  background-color: #306030;
  border-color: #306030;
}

.list-group-item-installation-existing {
  color: #853012;
  background-color: #ffd1c1;
}
.list-group-item-installation-existing.list-group-item-action:hover, .list-group-item-installation-existing.list-group-item-action:focus {
  color: #853012;
  background-color: #ffbea8;
}
.list-group-item-installation-existing.list-group-item-action.active {
  color: #fff;
  background-color: #853012;
  border-color: #853012;
}

.list-group-item-installation {
  color: #784300;
  background-color: #f8dcb8;
}
.list-group-item-installation.list-group-item-action:hover, .list-group-item-installation.list-group-item-action:focus {
  color: #784300;
  background-color: #f6d1a1;
}
.list-group-item-installation.list-group-item-action.active {
  color: #fff;
  background-color: #784300;
  border-color: #784300;
}

.list-group-item-gray {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-gray.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.list-group-item-gray-400 {
  color: #6b6e71;
  background-color: #f1f3f5;
}
.list-group-item-gray-400.list-group-item-action:hover, .list-group-item-gray-400.list-group-item-action:focus {
  color: #6b6e71;
  background-color: #e2e6ea;
}
.list-group-item-gray-400.list-group-item-action.active {
  color: #fff;
  background-color: #6b6e71;
  border-color: #6b6e71;
}

.list-group-item-factory-lab {
  color: #53065e;
  background-color: #e4bbea;
}
.list-group-item-factory-lab.list-group-item-action:hover, .list-group-item-factory-lab.list-group-item-action:focus {
  color: #53065e;
  background-color: #dca8e4;
}
.list-group-item-factory-lab.list-group-item-action.active {
  color: #fff;
  background-color: #53065e;
  border-color: #53065e;
}

.list-group-item-excel {
  color: #0f3a22;
  background-color: #c0d7ca;
}
.list-group-item-excel.list-group-item-action:hover, .list-group-item-excel.list-group-item-action:focus {
  color: #0f3a22;
  background-color: #b0cdbd;
}
.list-group-item-excel.list-group-item-action.active {
  color: #fff;
  background-color: #0f3a22;
  border-color: #0f3a22;
}

.list-group-item-admin {
  color: #858500;
  background-color: #ffffb8;
}
.list-group-item-admin.list-group-item-action:hover, .list-group-item-admin.list-group-item-action:focus {
  color: #858500;
  background-color: #ffff9f;
}
.list-group-item-admin.list-group-item-action.active {
  color: #fff;
  background-color: #858500;
  border-color: #858500;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
@media (max-width: 1200px) {
  .close {
    font-size: calc(1.275rem + 0.3vw);
  }
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 1rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #124e96 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0d3668 !important;
}

.bg-secondary {
  background-color: #d55e96 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #ca367c !important;
}

.bg-success, .card_selector.active {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.card_selector.active:hover, a.bg-success:focus, a.card_selector.active:focus,
button.bg-success:hover,
button.card_selector.active:hover,
button.bg-success:focus,
button.card_selector.active:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #e78100 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #b46500 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-primary-dark {
  background-color: #173563 !important;
}

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #0d1f3a !important;
}

.bg-default {
  background-color: #FFF !important;
}

a.bg-default:hover, a.bg-default:focus,
button.bg-default:hover,
button.bg-default:focus {
  background-color: #e6e6e6 !important;
}

.bg-add {
  background-color: #21A63D !important;
}

a.bg-add:hover, a.bg-add:focus,
button.bg-add:hover,
button.bg-add:focus {
  background-color: #197b2d !important;
}

.bg-delete {
  background-color: #D4667A !important;
}

a.bg-delete:hover, a.bg-delete:focus,
button.bg-delete:hover,
button.bg-delete:focus {
  background-color: #c93e57 !important;
}

.bg-nav {
  background-color: #6c6b6b !important;
}

a.bg-nav:hover, a.bg-nav:focus,
button.bg-nav:hover,
button.bg-nav:focus {
  background-color: #525252 !important;
}

.bg-measurement-existing {
  background-color: #5cb85c !important;
}

a.bg-measurement-existing:hover, a.bg-measurement-existing:focus,
button.bg-measurement-existing:hover,
button.bg-measurement-existing:focus {
  background-color: #449d44 !important;
}

.bg-installation-existing {
  background-color: #ff5c22 !important;
}

a.bg-installation-existing:hover, a.bg-installation-existing:focus,
button.bg-installation-existing:hover,
button.bg-installation-existing:focus {
  background-color: #ee3e00 !important;
}

.bg-installation {
  background-color: #e78100 !important;
}

a.bg-installation:hover, a.bg-installation:focus,
button.bg-installation:hover,
button.bg-installation:focus {
  background-color: #b46500 !important;
}

.bg-gray {
  background-color: #343a40 !important;
}

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #1d2124 !important;
}

.bg-gray-400 {
  background-color: #ced4da !important;
}

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #b1bbc4 !important;
}

.bg-factory-lab {
  background-color: rgb(159, 12, 180) !important;
}

a.bg-factory-lab:hover, a.bg-factory-lab:focus,
button.bg-factory-lab:hover,
button.bg-factory-lab:focus {
  background-color: #750984 !important;
}

.bg-excel {
  background-color: #1d6f42 !important;
}

a.bg-excel:hover, a.bg-excel:focus,
button.bg-excel:hover,
button.bg-excel:focus {
  background-color: #12472a !important;
}

.bg-admin {
  background-color: #FFFF00 !important;
}

a.bg-admin:hover, a.bg-admin:focus,
button.bg-admin:hover,
button.bg-admin:focus {
  background-color: #cccc00 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #124e96 !important;
}

.border-secondary {
  border-color: #d55e96 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #e78100 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-primary-dark {
  border-color: #173563 !important;
}

.border-default {
  border-color: #FFF !important;
}

.border-add {
  border-color: #21A63D !important;
}

.border-delete {
  border-color: #D4667A !important;
}

.border-nav {
  border-color: #6c6b6b !important;
}

.border-measurement-existing {
  border-color: #5cb85c !important;
}

.border-installation-existing {
  border-color: #ff5c22 !important;
}

.border-installation {
  border-color: #e78100 !important;
}

.border-gray {
  border-color: #343a40 !important;
}

.border-gray-400 {
  border-color: #ced4da !important;
}

.border-factory-lab {
  border-color: rgb(159, 12, 180) !important;
}

.border-excel {
  border-color: #1d6f42 !important;
}

.border-admin {
  border-color: #FFFF00 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle, .user-card img, .img-avatar-lg, .img-avatar {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100, .modal-fluid {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.375rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.375rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.375rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.375rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.375rem !important;
}

.m-2 {
  margin: 0.75rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.75rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.75rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.75rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.75rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1.5rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1.5rem !important;
}

.m-4 {
  margin: 2.25rem !important;
}

.mt-4,
.my-4 {
  margin-top: 2.25rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 2.25rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 2.25rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 2.25rem !important;
}

.m-5 {
  margin: 4.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 4.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 4.5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 4.5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 4.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.375rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.375rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.375rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.375rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.375rem !important;
}

.p-2 {
  padding: 0.75rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.75rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.75rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.75rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.75rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.5rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1.5rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1.5rem !important;
}

.p-4 {
  padding: 2.25rem !important;
}

.pt-4,
.py-4 {
  padding-top: 2.25rem !important;
}

.pr-4,
.px-4 {
  padding-right: 2.25rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 2.25rem !important;
}

.pl-4,
.px-4 {
  padding-left: 2.25rem !important;
}

.p-5 {
  padding: 4.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 4.5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 4.5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 4.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 4.5rem !important;
}

.m-n1 {
  margin: -0.375rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.375rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.375rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.375rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.375rem !important;
}

.m-n2 {
  margin: -0.75rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.75rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.75rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.75rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.75rem !important;
}

.m-n3 {
  margin: -1.5rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1.5rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1.5rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1.5rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1.5rem !important;
}

.m-n4 {
  margin: -2.25rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -2.25rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -2.25rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -2.25rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -2.25rem !important;
}

.m-n5 {
  margin: -4.5rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -4.5rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -4.5rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -4.5rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.375rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.375rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.375rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.375rem !important;
  }
  .m-sm-2 {
    margin: 0.75rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.75rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.75rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.75rem !important;
  }
  .m-sm-3 {
    margin: 1.5rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.5rem !important;
  }
  .m-sm-4 {
    margin: 2.25rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2.25rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2.25rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2.25rem !important;
  }
  .m-sm-5 {
    margin: 4.5rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 4.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.375rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.375rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.375rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.375rem !important;
  }
  .p-sm-2 {
    padding: 0.75rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.75rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.75rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.75rem !important;
  }
  .p-sm-3 {
    padding: 1.5rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.5rem !important;
  }
  .p-sm-4 {
    padding: 2.25rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2.25rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2.25rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2.25rem !important;
  }
  .p-sm-5 {
    padding: 4.5rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 4.5rem !important;
  }
  .m-sm-n1 {
    margin: -0.375rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.375rem !important;
  }
  .m-sm-n2 {
    margin: -0.75rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.75rem !important;
  }
  .m-sm-n3 {
    margin: -1.5rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n4 {
    margin: -2.25rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -2.25rem !important;
  }
  .m-sm-n5 {
    margin: -4.5rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -4.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.375rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.375rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.375rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.375rem !important;
  }
  .m-md-2 {
    margin: 0.75rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.75rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.75rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.75rem !important;
  }
  .m-md-3 {
    margin: 1.5rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.5rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.5rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.5rem !important;
  }
  .m-md-4 {
    margin: 2.25rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2.25rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2.25rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2.25rem !important;
  }
  .m-md-5 {
    margin: 4.5rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 4.5rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 4.5rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 4.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.375rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.375rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.375rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.375rem !important;
  }
  .p-md-2 {
    padding: 0.75rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.75rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.75rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.75rem !important;
  }
  .p-md-3 {
    padding: 1.5rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.5rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.5rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.5rem !important;
  }
  .p-md-4 {
    padding: 2.25rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2.25rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2.25rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2.25rem !important;
  }
  .p-md-5 {
    padding: 4.5rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 4.5rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 4.5rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 4.5rem !important;
  }
  .m-md-n1 {
    margin: -0.375rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.375rem !important;
  }
  .m-md-n2 {
    margin: -0.75rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.75rem !important;
  }
  .m-md-n3 {
    margin: -1.5rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1.5rem !important;
  }
  .m-md-n4 {
    margin: -2.25rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -2.25rem !important;
  }
  .m-md-n5 {
    margin: -4.5rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -4.5rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -4.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.375rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.375rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.375rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.375rem !important;
  }
  .m-lg-2 {
    margin: 0.75rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.75rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.75rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.75rem !important;
  }
  .m-lg-3 {
    margin: 1.5rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.5rem !important;
  }
  .m-lg-4 {
    margin: 2.25rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2.25rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2.25rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2.25rem !important;
  }
  .m-lg-5 {
    margin: 4.5rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 4.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.375rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.375rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.375rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.375rem !important;
  }
  .p-lg-2 {
    padding: 0.75rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.75rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.75rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.75rem !important;
  }
  .p-lg-3 {
    padding: 1.5rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.5rem !important;
  }
  .p-lg-4 {
    padding: 2.25rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2.25rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2.25rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2.25rem !important;
  }
  .p-lg-5 {
    padding: 4.5rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 4.5rem !important;
  }
  .m-lg-n1 {
    margin: -0.375rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.375rem !important;
  }
  .m-lg-n2 {
    margin: -0.75rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.75rem !important;
  }
  .m-lg-n3 {
    margin: -1.5rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n4 {
    margin: -2.25rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -2.25rem !important;
  }
  .m-lg-n5 {
    margin: -4.5rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -4.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.375rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.375rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.375rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.375rem !important;
  }
  .m-xl-2 {
    margin: 0.75rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.75rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.75rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.75rem !important;
  }
  .m-xl-3 {
    margin: 1.5rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.5rem !important;
  }
  .m-xl-4 {
    margin: 2.25rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2.25rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2.25rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2.25rem !important;
  }
  .m-xl-5 {
    margin: 4.5rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 4.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.375rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.375rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.375rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.375rem !important;
  }
  .p-xl-2 {
    padding: 0.75rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.75rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.75rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.75rem !important;
  }
  .p-xl-3 {
    padding: 1.5rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.5rem !important;
  }
  .p-xl-4 {
    padding: 2.25rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2.25rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2.25rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2.25rem !important;
  }
  .p-xl-5 {
    padding: 4.5rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 4.5rem !important;
  }
  .m-xl-n1 {
    margin: -0.375rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.375rem !important;
  }
  .m-xl-n2 {
    margin: -0.75rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.75rem !important;
  }
  .m-xl-n3 {
    margin: -1.5rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n4 {
    margin: -2.25rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -2.25rem !important;
  }
  .m-xl-n5 {
    margin: -4.5rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -4.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white, .card_selector.active {
  color: #fff !important;
}

.text-primary {
  color: #124e96 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0a2a52 !important;
}

.text-secondary {
  color: #d55e96 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #b7306f !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #e78100 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #9b5600 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-primary-dark {
  color: #173563 !important;
}

a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: #091425 !important;
}

.text-default {
  color: #FFF !important;
}

a.text-default:hover, a.text-default:focus {
  color: #d9d9d9 !important;
}

.text-add {
  color: #21A63D !important;
}

a.text-add:hover, a.text-add:focus {
  color: #146626 !important;
}

.text-delete {
  color: #D4667A !important;
}

a.text-delete:hover, a.text-delete:focus {
  color: #b9344c !important;
}

.text-nav {
  color: #6c6b6b !important;
}

a.text-nav:hover, a.text-nav:focus {
  color: #464545 !important;
}

.text-measurement-existing {
  color: #5cb85c !important;
}

a.text-measurement-existing:hover, a.text-measurement-existing:focus {
  color: #3d8b3d !important;
}

.text-installation-existing {
  color: #ff5c22 !important;
}

a.text-installation-existing:hover, a.text-installation-existing:focus {
  color: #d53800 !important;
}

.text-installation {
  color: #e78100 !important;
}

a.text-installation:hover, a.text-installation:focus {
  color: #9b5600 !important;
}

.text-gray {
  color: #343a40 !important;
}

a.text-gray:hover, a.text-gray:focus {
  color: #121416 !important;
}

.text-gray-400 {
  color: #ced4da !important;
}

a.text-gray-400:hover, a.text-gray-400:focus {
  color: #a2aeb9 !important;
}

.text-factory-lab {
  color: rgb(159, 12, 180) !important;
}

a.text-factory-lab:hover, a.text-factory-lab:focus {
  color: #60076c !important;
}

.text-excel {
  color: #1d6f42 !important;
}

a.text-excel:hover, a.text-excel:focus {
  color: #0d321e !important;
}

.text-admin {
  color: #FFFF00 !important;
}

a.text-admin:hover, a.text-admin:focus {
  color: #b3b300 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.alert > ul:last-child {
  margin-bottom: 0;
}

.abc-checkbox {
  cursor: default;
  padding-left: 4px;
}
.abc-checkbox label {
  cursor: pointer;
  display: inline;
  vertical-align: top;
  position: relative;
  padding-left: 5px;
}
.abc-checkbox label::before {
  cursor: pointer;
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 2px;
  left: 0;
  margin-left: -1.25rem;
  border: 1px solid #ced4da;
  border-radius: 3px;
  background-color: #fff;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .abc-checkbox label::before {
    transition: none;
  }
}
.abc-checkbox label::after {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 2px;
  margin-left: -1.25rem;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #495057;
}
.abc-checkbox input[type=checkbox],
.abc-checkbox input[type=radio] {
  position: static;
  margin-left: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
}
.abc-checkbox input[type=checkbox]:focus + label::before,
.abc-checkbox input[type=radio]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.abc-checkbox input[type=checkbox]:checked + label::after,
.abc-checkbox input[type=radio]:checked + label::after {
  font-family: "Font Awesome 6 Free";
  content: "\f00c";
}
.abc-checkbox input[type=checkbox]:indeterminate + label::after,
.abc-checkbox input[type=radio]:indeterminate + label::after {
  display: block;
  content: "";
  width: 10px;
  height: 3px;
  background-color: #555555;
  border-radius: 2px;
  margin-left: -16.5px;
  margin-top: 7px;
}
.abc-checkbox input[type=checkbox]:disabled + label,
.abc-checkbox input[type=radio]:disabled + label {
  opacity: 0.65;
}
.abc-checkbox input[type=checkbox]:disabled + label::before,
.abc-checkbox input[type=radio]:disabled + label::before {
  background-color: #e9ecef;
  cursor: not-allowed;
}
.abc-checkbox input[type=checkbox]:disabled + label::after,
.abc-checkbox input[type=radio]:disabled + label::after {
  cursor: not-allowed;
}
.abc-checkbox.abc-checkbox-circle label::before {
  border-radius: 50%;
}
.abc-checkbox.checkbox-inline {
  margin-top: 0;
}

.abc-checkbox-primary input[type=checkbox]:checked + label::before,
.abc-checkbox-primary input[type=radio]:checked + label::before {
  background-color: #124e96;
  border-color: #124e96;
}
.abc-checkbox-primary input[type=checkbox]:checked + label::after,
.abc-checkbox-primary input[type=radio]:checked + label::after {
  color: #fff;
}

.abc-checkbox-danger input[type=checkbox]:checked + label::before,
.abc-checkbox-danger input[type=radio]:checked + label::before {
  background-color: #dc3545;
  border-color: #dc3545;
}
.abc-checkbox-danger input[type=checkbox]:checked + label::after,
.abc-checkbox-danger input[type=radio]:checked + label::after {
  color: #fff;
}

.abc-checkbox-info input[type=checkbox]:checked + label::before,
.abc-checkbox-info input[type=radio]:checked + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.abc-checkbox-info input[type=checkbox]:checked + label::after,
.abc-checkbox-info input[type=radio]:checked + label::after {
  color: #fff;
}

.abc-checkbox-warning input[type=checkbox]:checked + label::before,
.abc-checkbox-warning input[type=radio]:checked + label::before {
  background-color: #e78100;
  border-color: #e78100;
}
.abc-checkbox-warning input[type=checkbox]:checked + label::after,
.abc-checkbox-warning input[type=radio]:checked + label::after {
  color: #fff;
}

.abc-checkbox-success input[type=checkbox]:checked + label::before,
.abc-checkbox-success input[type=radio]:checked + label::before {
  background-color: #28a745;
  border-color: #28a745;
}
.abc-checkbox-success input[type=checkbox]:checked + label::after,
.abc-checkbox-success input[type=radio]:checked + label::after {
  color: #fff;
}

.abc-checkbox-primary input[type=checkbox]:indeterminate + label::before,
.abc-checkbox-primary input[type=radio]:indeterminate + label::before {
  background-color: #124e96;
  border-color: #124e96;
}
.abc-checkbox-primary input[type=checkbox]:indeterminate + label::after,
.abc-checkbox-primary input[type=radio]:indeterminate + label::after {
  background-color: #fff;
}

.abc-checkbox-danger input[type=checkbox]:indeterminate + label::before,
.abc-checkbox-danger input[type=radio]:indeterminate + label::before {
  background-color: #dc3545;
  border-color: #dc3545;
}
.abc-checkbox-danger input[type=checkbox]:indeterminate + label::after,
.abc-checkbox-danger input[type=radio]:indeterminate + label::after {
  background-color: #fff;
}

.abc-checkbox-info input[type=checkbox]:indeterminate + label::before,
.abc-checkbox-info input[type=radio]:indeterminate + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.abc-checkbox-info input[type=checkbox]:indeterminate + label::after,
.abc-checkbox-info input[type=radio]:indeterminate + label::after {
  background-color: #fff;
}

.abc-checkbox-warning input[type=checkbox]:indeterminate + label::before,
.abc-checkbox-warning input[type=radio]:indeterminate + label::before {
  background-color: #e78100;
  border-color: #e78100;
}
.abc-checkbox-warning input[type=checkbox]:indeterminate + label::after,
.abc-checkbox-warning input[type=radio]:indeterminate + label::after {
  background-color: #fff;
}

.abc-checkbox-success input[type=checkbox]:indeterminate + label::before,
.abc-checkbox-success input[type=radio]:indeterminate + label::before {
  background-color: #28a745;
  border-color: #28a745;
}
.abc-checkbox-success input[type=checkbox]:indeterminate + label::after,
.abc-checkbox-success input[type=radio]:indeterminate + label::after {
  background-color: #fff;
}

.abc-radio {
  cursor: default;
  padding-left: 4px;
}
.abc-radio label {
  cursor: pointer;
  display: inline;
  vertical-align: top;
  position: relative;
  padding-left: 5px;
}
.abc-radio label::before {
  cursor: pointer;
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 2px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #ced4da;
  border-radius: 50%;
  background-color: #fff;
  transition: border 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .abc-radio label::before {
    transition: none;
  }
}
.abc-radio label::after {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  content: " ";
  width: 11px;
  height: 11px;
  left: 3px;
  top: 5px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #495057;
  transform: scale(0, 0);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}
.abc-radio input[type=radio] {
  position: static;
  margin-left: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
}
.abc-radio input[type=radio]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.abc-radio input[type=radio]:checked + label::after {
  transform: scale(1, 1);
}
.abc-radio input[type=radio]:disabled + label {
  opacity: 0.65;
}
.abc-radio input[type=radio]:disabled + label::before {
  cursor: not-allowed;
}
.abc-radio input[type=radio]:disabled + label::after {
  cursor: not-allowed;
}
.abc-radio.radio-inline {
  margin-top: 0;
}

.abc-radio-primary input[type=radio] + label::after {
  background-color: #124e96;
}
.abc-radio-primary input[type=radio]:checked + label::before {
  border-color: #124e96;
}
.abc-radio-primary input[type=radio]:checked + label::after {
  background-color: #124e96;
}

.abc-radio-danger input[type=radio] + label::after {
  background-color: #dc3545;
}
.abc-radio-danger input[type=radio]:checked + label::before {
  border-color: #dc3545;
}
.abc-radio-danger input[type=radio]:checked + label::after {
  background-color: #dc3545;
}

.abc-radio-info input[type=radio] + label::after {
  background-color: #17a2b8;
}
.abc-radio-info input[type=radio]:checked + label::before {
  border-color: #17a2b8;
}
.abc-radio-info input[type=radio]:checked + label::after {
  background-color: #17a2b8;
}

.abc-radio-warning input[type=radio] + label::after {
  background-color: #e78100;
}
.abc-radio-warning input[type=radio]:checked + label::before {
  border-color: #e78100;
}
.abc-radio-warning input[type=radio]:checked + label::after {
  background-color: #e78100;
}

.abc-radio-success input[type=radio] + label::after {
  background-color: #28a745;
}
.abc-radio-success input[type=radio]:checked + label::before {
  border-color: #28a745;
}
.abc-radio-success input[type=radio]:checked + label::after {
  background-color: #28a745;
}

label .was-validated .form-check-input:invalid .abc-checkbox:before,
label .was-validated .form-check-input:invalid .abc-radio:before, label .form-check-input.is-invalid .abc-checkbox:before,
label .form-check-input.is-invalid .abc-radio:before {
  border-color: #dc3545;
}

.abc-checkbox input[type=checkbox]:checked + label::after,
.abc-checkbox input[type=radio]:checked + label::after {
  font-weight: 900;
}

.bg-gray-100 {
  background-color: #f8f9fa;
}
.bg-gray-200 {
  background-color: #e9ecef;
}
.bg-gray-300 {
  background-color: #dee2e6;
}
.bg-gray-400 {
  background-color: #ced4da;
}
.bg-gray-500 {
  background-color: #adb5bd;
}
.bg-gray-600 {
  background-color: #6c757d;
}
.bg-gray-700 {
  background-color: #495057;
}
.bg-gray-800 {
  background-color: #343a40;
}
.bg-gray-900 {
  background-color: #212529;
}

.bg-stats-ok {
  background-color: #7cb342;
}
.bg-stats-not-ok {
  background-color: #e53935;
}
.bg-stats-ignored {
  background-color: #c0c0c0;
}
.bg-stats-not-ok-but-handled {
  background-color: #fb8c00;
}
.bg-stats-not-ok-transparent {
  background-color: rgba(229, 57, 53, 0.15);
}
.bg-stats-pending {
  background-color: #3da2b8;
}

.is--migrated .badge {
  font-size: 100%;
}

.badge-disabled {
  background-color: #adb5bd;
  color: #000;
}

.badge-sm {
  font-size: 0.875rem;
}

.badge-light {
  background-color: #7695bf;
  color: #173563;
}

.badge-lightest {
  background-color: #f1f4f9;
  color: #173563;
}

.badge-rounded {
  border-radius: 4px;
}

.multi-select-bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1020;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  color: #212529;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.multi-select-bottom-bar .bottom_bar_submenu_container {
  position: relative;
}
.multi-select-bottom-bar .bottom_bar_submenu_container .bottom_bar_submenu {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  min-width: 100%;
  bottom: 0;
  left: -0.75rem;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 0;
}

.btn[data-toggle=collapse] .fa.fa-chevron-down {
  transition: height 0.35s ease;
  transition-property: transform;
  transform: rotate(180deg);
}
.btn[data-toggle=collapse].collapsed .fa.fa-chevron-down {
  transform: rotate(0deg);
  filter: none;
}

.btn-bulk {
  height: 100%;
  display: block;
  border: 1px solid #E0E0E0;
  box-shadow: 0 1px 1px rgba(177, 177, 177, 0.25), inset 0 1px 0 rgba(255, 255, 255, 0.25);
  border-radius: 1rem;
  margin: 1rem 0;
  padding: 1.5rem 1.5rem 0;
}
.btn-bulk__title {
  margin: 0;
}
.btn-bulk__icon {
  width: calc(100% + 2rem);
  margin: 0 -1rem 1rem;
}
.btn-bulk__icon img {
  max-width: 210px;
  width: 100%;
}
.btn-bulk:hover {
  background-color: rgb(227, 233, 241);
  box-shadow: 1px 1px 8px rgba(18, 78, 150, 0.17);
}
.btn-bulk:active, .btn-bulk:focus {
  background-color: rgb(199, 213, 226);
  box-shadow: inset 2px 2px 7px rgba(18, 78, 150, 0.07);
}

.btn-disabled {
  color: #212529;
  background-color: #adb5bd;
  border-color: #000;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-disabled:hover {
  color: #212529;
  background-color: #98a2ac;
  border-color: black;
}
.btn-disabled:focus, .btn-disabled.focus {
  color: #212529;
  background-color: #98a2ac;
  border-color: black;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(5, 6, 6, 0.5);
}
.btn-disabled.disabled, .btn-disabled:disabled {
  color: #212529;
  background-color: #adb5bd;
  border-color: #000;
}
.btn-disabled:not(:disabled):not(.disabled):active, .btn-disabled:not(:disabled):not(.disabled).active, .show > .btn-disabled.dropdown-toggle {
  color: #212529;
  background-color: #919ca6;
  border-color: black;
}
.btn-disabled:not(:disabled):not(.disabled):active:focus, .btn-disabled:not(:disabled):not(.disabled).active:focus, .show > .btn-disabled.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(5, 6, 6, 0.5);
}

.card-footer.card-footer-sticky {
  position: sticky;
  bottom: -1px;
}

.card {
  overflow-wrap: normal;
  word-break: normal;
}
.card .card-header + .card-table, .card .card-header + .table-responsive {
  border-top: 0;
}
.card .card-header + .card-table .table-bordered th, .card .card-header + .card-table th, .card .card-header + .table-responsive .table-bordered th, .card .card-header + .table-responsive th {
  border-top: 0;
}
.card .card-body .card-row {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
.card > .table:last-child, .card > .table-responsive:last-child .table {
  margin-bottom: 0;
}

.card-footer {
  background-color: rgba(0, 0, 0, 0.05);
}
.card-footer.card-footer-sticky {
  background-color: rgb(244, 244, 244);
}

.card-header > .row, .card-header > .craue_formflow_buttons, .card .card-body .card-header > .card-row {
  align-items: center;
}

input.form-check-lg {
  width: 1.5rem;
  height: 1.5rem;
}

fieldset legend {
  border-bottom: 1px solid #dee2e6;
  background-color: #fff;
}

.form-check-expanded.is-valid ~ .valid-feedback {
  display: block;
}
.form-check-expanded.is-invalid ~ .invalid-feedback {
  display: block;
}
.form-check-expanded.is-invalid {
  color: #dc3545;
}
.form-check-expanded.is-valid {
  color: #28a745;
}

.form-check + .form-check {
  margin-top: 0.5rem;
}

.form-check.abc-checkbox label, .form-check.abc-radio label {
  user-select: none;
  padding-top: 2px;
}

.inline-icon-container {
  position: relative;
}
.inline-icon-container .inline-icon {
  position: absolute;
  top: 0;
  right: 0;
}
.inline-icon-container .form-control ~ .inline-icon {
  top: 0.375rem;
  right: 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}
.inline-icon-container .form-control-lg ~ .inline-icon {
  top: 0.5rem;
  right: 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.inline-icon-container .form-control-sm ~ .inline-icon {
  top: 0.25rem;
  right: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.custom-file-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.form-label-lg {
  font-size: 1.25rem;
}

.datetime-edit-field,
.datetime-input-box-wrapper,
input::-webkit-datetime-edit-fields-wrapper {
  text-transform: uppercase;
}

.form-control[autocomplete=one-time-code] {
  --spacing: 1.25ch;
  --size: 1.75rem;
  font-family: ui-monospace, monospace;
  font-size: var(--size);
  letter-spacing: var(--spacing);
  width: calc(6 * (var(--size) + var(--spacing)));
}
.form-control[autocomplete=one-time-code]::placeholder {
  color: #ced4da;
}

form[name=component_move_save_form].is-submitting {
  position: relative;
}
form[name=component_move_save_form].is-submitting::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1000;
}

i.indicator {
  transition: all 0.2s ease-in-out;
}
i.indicator.flipped {
  transform: rotate(-180deg);
}

a.disabled {
  color: #495057;
}

.modal.modal-primary-dark .modal-header {
  background-color: #173563;
  color: #fff;
}
.modal.modal-primary-dark .modal-header .close {
  color: #fff;
}

.modal.modal-default .modal-header {
  background-color: #FFF;
  color: #212529;
}
.modal.modal-default .modal-header .close {
  color: #212529;
}

.modal.modal-add .modal-header {
  background-color: #21A63D;
  color: #fff;
}
.modal.modal-add .modal-header .close {
  color: #fff;
}

.modal.modal-delete .modal-header {
  background-color: #D4667A;
  color: #fff;
}
.modal.modal-delete .modal-header .close {
  color: #fff;
}

.modal.modal-nav .modal-header {
  background-color: #6c6b6b;
  color: #fff;
}
.modal.modal-nav .modal-header .close {
  color: #fff;
}

.modal.modal-measurement-existing .modal-header {
  background-color: #5cb85c;
  color: #fff;
}
.modal.modal-measurement-existing .modal-header .close {
  color: #fff;
}

.modal.modal-installation-existing .modal-header {
  background-color: #ff5c22;
  color: #fff;
}
.modal.modal-installation-existing .modal-header .close {
  color: #fff;
}

.modal.modal-installation .modal-header {
  background-color: #e78100;
  color: #fff;
}
.modal.modal-installation .modal-header .close {
  color: #fff;
}

.modal.modal-gray .modal-header {
  background-color: #343a40;
  color: #fff;
}
.modal.modal-gray .modal-header .close {
  color: #fff;
}

.modal.modal-gray-400 .modal-header {
  background-color: #ced4da;
  color: #212529;
}
.modal.modal-gray-400 .modal-header .close {
  color: #212529;
}

.modal.modal-factory-lab .modal-header {
  background-color: rgb(159, 12, 180);
  color: #fff;
}
.modal.modal-factory-lab .modal-header .close {
  color: #fff;
}

.modal.modal-excel .modal-header {
  background-color: #1d6f42;
  color: #fff;
}
.modal.modal-excel .modal-header .close {
  color: #fff;
}

.modal.modal-admin .modal-header {
  background-color: #FFFF00;
  color: #212529;
}
.modal.modal-admin .modal-header .close {
  color: #212529;
}

.modal.modal-primary .modal-header {
  background-color: #124e96;
  color: #fff;
}
.modal.modal-primary .modal-header .close {
  color: #fff;
}

.modal.modal-secondary .modal-header {
  background-color: #d55e96;
  color: #fff;
}
.modal.modal-secondary .modal-header .close {
  color: #fff;
}

.modal.modal-success .modal-header {
  background-color: #28a745;
  color: #fff;
}
.modal.modal-success .modal-header .close {
  color: #fff;
}

.modal.modal-info .modal-header {
  background-color: #17a2b8;
  color: #fff;
}
.modal.modal-info .modal-header .close {
  color: #fff;
}

.modal.modal-warning .modal-header {
  background-color: #e78100;
  color: #fff;
}
.modal.modal-warning .modal-header .close {
  color: #fff;
}

.modal.modal-danger .modal-header {
  background-color: #dc3545;
  color: #fff;
}
.modal.modal-danger .modal-header .close {
  color: #fff;
}

.modal.modal-light .modal-header {
  background-color: #f8f9fa;
  color: #212529;
}
.modal.modal-light .modal-header .close {
  color: #212529;
}

.modal.modal-dark .modal-header {
  background-color: #343a40;
  color: #fff;
}
.modal.modal-dark .modal-header .close {
  color: #fff;
}

.modal {
  scroll-behavior: smooth;
}

.modal-content {
  border: none;
}
.modal-content img {
  max-width: 100%;
}

.modal-fluid {
  width: 95% !important;
}

.modal-content .modal-footer {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 10;
}

.nav.nav-tabs > .nav-item.nav-item-primary-dark > a {
  background-color: #e3e7ec;
  border-color: #bec6d3;
  color: #0c1c33;
}
.nav.nav-tabs > .nav-item.nav-item-primary-dark > a.active {
  background-color: #bec6d3;
}
.nav.nav-tabs > .nav-item.nav-item-primary-dark > a:hover {
  background-color: #d1d7e0;
}
.nav.nav-tabs > .nav-item.nav-item-default > a {
  background-color: white;
  border-color: white;
  color: #858585;
}
.nav.nav-tabs > .nav-item.nav-item-default > a.active {
  background-color: white;
}
.nav.nav-tabs > .nav-item.nav-item-default > a:hover {
  background-color: white;
}
.nav.nav-tabs > .nav-item.nav-item-add > a {
  background-color: #e4f4e8;
  border-color: #c1e6c9;
  color: #115620;
}
.nav.nav-tabs > .nav-item.nav-item-add > a.active {
  background-color: #c1e6c9;
}
.nav.nav-tabs > .nav-item.nav-item-add > a:hover {
  background-color: #d3edd8;
}
.nav.nav-tabs > .nav-item.nav-item-delete > a {
  background-color: #faedef;
  border-color: #f3d4da;
  color: #6e353f;
}
.nav.nav-tabs > .nav-item.nav-item-delete > a.active {
  background-color: #f3d4da;
}
.nav.nav-tabs > .nav-item.nav-item-delete > a:hover {
  background-color: #f6e0e4;
}
.nav.nav-tabs > .nav-item.nav-item-nav > a {
  background-color: #ededed;
  border-color: #d6d6d6;
  color: #383838;
}
.nav.nav-tabs > .nav-item.nav-item-nav > a.active {
  background-color: #d6d6d6;
}
.nav.nav-tabs > .nav-item.nav-item-nav > a:hover {
  background-color: #e2e1e1;
}
.nav.nav-tabs > .nav-item.nav-item-measurement-existing > a {
  background-color: #ebf6eb;
  border-color: #d1ebd1;
  color: #306030;
}
.nav.nav-tabs > .nav-item.nav-item-measurement-existing > a.active {
  background-color: #d1ebd1;
}
.nav.nav-tabs > .nav-item.nav-item-measurement-existing > a:hover {
  background-color: #def1de;
}
.nav.nav-tabs > .nav-item.nav-item-installation-existing > a {
  background-color: #ffebe4;
  border-color: #ffd1c1;
  color: #853012;
}
.nav.nav-tabs > .nav-item.nav-item-installation-existing > a.active {
  background-color: #ffd1c1;
}
.nav.nav-tabs > .nav-item.nav-item-installation-existing > a:hover {
  background-color: #ffded3;
}
.nav.nav-tabs > .nav-item.nav-item-installation > a {
  background-color: #fcf0e0;
  border-color: #f8dcb8;
  color: #784300;
}
.nav.nav-tabs > .nav-item.nav-item-installation > a.active {
  background-color: #f8dcb8;
}
.nav.nav-tabs > .nav-item.nav-item-installation > a:hover {
  background-color: #fae6cc;
}
.nav.nav-tabs > .nav-item.nav-item-gray > a {
  background-color: #e7e7e8;
  border-color: #c6c8ca;
  color: #1b1e21;
}
.nav.nav-tabs > .nav-item.nav-item-gray > a.active {
  background-color: #c6c8ca;
}
.nav.nav-tabs > .nav-item.nav-item-gray > a:hover {
  background-color: #d6d8d9;
}
.nav.nav-tabs > .nav-item.nav-item-gray-400 > a {
  background-color: #f9fafb;
  border-color: #f1f3f5;
  color: #6b6e71;
}
.nav.nav-tabs > .nav-item.nav-item-gray-400 > a.active {
  background-color: #f1f3f5;
}
.nav.nav-tabs > .nav-item.nav-item-gray-400 > a:hover {
  background-color: #f5f6f8;
}
.nav.nav-tabs > .nav-item.nav-item-factory-lab > a {
  background-color: #f3e2f6;
  border-color: #e4bbea;
  color: #53065e;
}
.nav.nav-tabs > .nav-item.nav-item-factory-lab > a.active {
  background-color: #e4bbea;
}
.nav.nav-tabs > .nav-item.nav-item-factory-lab > a:hover {
  background-color: #eccef0;
}
.nav.nav-tabs > .nav-item.nav-item-excel > a {
  background-color: #e4eee8;
  border-color: #c0d7ca;
  color: #0f3a22;
}
.nav.nav-tabs > .nav-item.nav-item-excel > a.active {
  background-color: #c0d7ca;
}
.nav.nav-tabs > .nav-item.nav-item-excel > a:hover {
  background-color: #d2e2d9;
}
.nav.nav-tabs > .nav-item.nav-item-admin > a {
  background-color: lightyellow;
  border-color: #ffffb8;
  color: #858500;
}
.nav.nav-tabs > .nav-item.nav-item-admin > a.active {
  background-color: #ffffb8;
}
.nav.nav-tabs > .nav-item.nav-item-admin > a:hover {
  background-color: #ffffcc;
}
.nav.nav-tabs > .nav-item.nav-item-primary > a {
  background-color: #e3eaf2;
  border-color: #bdcde2;
  color: #09294e;
}
.nav.nav-tabs > .nav-item.nav-item-primary > a.active {
  background-color: #bdcde2;
}
.nav.nav-tabs > .nav-item.nav-item-primary > a:hover {
  background-color: #d0dcea;
}
.nav.nav-tabs > .nav-item.nav-item-secondary > a {
  background-color: #faecf2;
  border-color: #f3d2e2;
  color: #6f314e;
}
.nav.nav-tabs > .nav-item.nav-item-secondary > a.active {
  background-color: #f3d2e2;
}
.nav.nav-tabs > .nav-item.nav-item-secondary > a:hover {
  background-color: #f7dfea;
}
.nav.nav-tabs > .nav-item.nav-item-success > a {
  background-color: #e5f4e9;
  border-color: #c3e6cb;
  color: #155724;
}
.nav.nav-tabs > .nav-item.nav-item-success > a.active {
  background-color: #c3e6cb;
}
.nav.nav-tabs > .nav-item.nav-item-success > a:hover {
  background-color: #d4edda;
}
.nav.nav-tabs > .nav-item.nav-item-info > a {
  background-color: #e3f4f6;
  border-color: #bee5eb;
  color: #0c5460;
}
.nav.nav-tabs > .nav-item.nav-item-info > a.active {
  background-color: #bee5eb;
}
.nav.nav-tabs > .nav-item.nav-item-info > a:hover {
  background-color: #d1ecf1;
}
.nav.nav-tabs > .nav-item.nav-item-warning > a {
  background-color: #fcf0e0;
  border-color: #f8dcb8;
  color: #784300;
}
.nav.nav-tabs > .nav-item.nav-item-warning > a.active {
  background-color: #f8dcb8;
}
.nav.nav-tabs > .nav-item.nav-item-warning > a:hover {
  background-color: #fae6cc;
}
.nav.nav-tabs > .nav-item.nav-item-danger > a {
  background-color: #fbe7e9;
  border-color: #f5c6cb;
  color: #721c24;
}
.nav.nav-tabs > .nav-item.nav-item-danger > a.active {
  background-color: #f5c6cb;
}
.nav.nav-tabs > .nav-item.nav-item-danger > a:hover {
  background-color: #f8d7da;
}
.nav.nav-tabs > .nav-item.nav-item-light > a {
  background-color: #fefefe;
  border-color: #fdfdfe;
  color: #818182;
}
.nav.nav-tabs > .nav-item.nav-item-light > a.active {
  background-color: #fdfdfe;
}
.nav.nav-tabs > .nav-item.nav-item-light > a:hover {
  background-color: #fefefe;
}
.nav.nav-tabs > .nav-item.nav-item-dark > a {
  background-color: #e7e7e8;
  border-color: #c6c8ca;
  color: #1b1e21;
}
.nav.nav-tabs > .nav-item.nav-item-dark > a.active {
  background-color: #c6c8ca;
}
.nav.nav-tabs > .nav-item.nav-item-dark > a:hover {
  background-color: #d6d8d9;
}

.nav.nav-tabs {
  z-index: 1021;
}
.nav.nav-tabs .nav-link.active {
  background-color: transparent;
  border-bottom-color: white;
}
.nav.nav-tabs .dropdown-menu .dropdown-item.active {
  position: relative;
}
.nav.nav-tabs .dropdown-menu .dropdown-item.active:before {
  content: "•";
  font-size: 1.5em;
  left: 0.375em;
  position: absolute;
  top: 0;
}

.dropdown-toggle.dropdown-toggle-empty::after {
  margin-left: 0;
}

.main-navbar {
  align-items: stretch;
  font-size: 15px;
}

.navbar .navbar-nav > li {
  display: flex;
  align-items: center;
}
.navbar .navbar-nav > li.nav-item {
  height: 100%;
}
.navbar .navbar-nav > li.nav-item > a {
  display: flex;
  align-items: center;
}
.navbar .navbar-nav > li.nav-item > a > .nav-text {
  margin-left: 0.75rem;
}

.navbar.bg-primary-dark.full-background .navbar-nav > li.nav-item:hover {
  background-color: #102443;
}
.navbar.bg-primary-dark.full-background .navbar-nav > li.nav-item.active {
  background-color: #132d53;
}
.navbar.bg-primary-dark.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #102443;
}

.navbar.bg-default.full-background .navbar-nav > li.nav-item:hover {
  background-color: #adadad;
}
.navbar.bg-default.full-background .navbar-nav > li.nav-item.active {
  background-color: #d6d6d6;
}
.navbar.bg-default.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #adadad;
}

.navbar.bg-add.full-background .navbar-nav > li.nav-item:hover {
  background-color: #167129;
}
.navbar.bg-add.full-background .navbar-nav > li.nav-item.active {
  background-color: #1c8b33;
}
.navbar.bg-add.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #167129;
}

.navbar.bg-delete.full-background .navbar-nav > li.nav-item:hover {
  background-color: #904553;
}
.navbar.bg-delete.full-background .navbar-nav > li.nav-item.active {
  background-color: #b25666;
}
.navbar.bg-delete.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #904553;
}

.navbar.bg-nav.full-background .navbar-nav > li.nav-item:hover {
  background-color: #494949;
}
.navbar.bg-nav.full-background .navbar-nav > li.nav-item.active {
  background-color: #5b5a5a;
}
.navbar.bg-nav.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #494949;
}

.navbar.bg-measurement-existing.full-background .navbar-nav > li.nav-item:hover {
  background-color: #3f7d3f;
}
.navbar.bg-measurement-existing.full-background .navbar-nav > li.nav-item.active {
  background-color: #4d9b4d;
}
.navbar.bg-measurement-existing.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #3f7d3f;
}

.navbar.bg-installation-existing.full-background .navbar-nav > li.nav-item:hover {
  background-color: #ad3f17;
}
.navbar.bg-installation-existing.full-background .navbar-nav > li.nav-item.active {
  background-color: #d64d1d;
}
.navbar.bg-installation-existing.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #ad3f17;
}

.navbar.bg-installation.full-background .navbar-nav > li.nav-item:hover {
  background-color: #9d5800;
}
.navbar.bg-installation.full-background .navbar-nav > li.nav-item.active {
  background-color: #c26c00;
}
.navbar.bg-installation.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #9d5800;
}

.navbar.bg-gray.full-background .navbar-nav > li.nav-item:hover {
  background-color: #23272c;
}
.navbar.bg-gray.full-background .navbar-nav > li.nav-item.active {
  background-color: #2c3136;
}
.navbar.bg-gray.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #23272c;
}

.navbar.bg-gray-400.full-background .navbar-nav > li.nav-item:hover {
  background-color: #8c9094;
}
.navbar.bg-gray-400.full-background .navbar-nav > li.nav-item.active {
  background-color: #adb2b7;
}
.navbar.bg-gray-400.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #8c9094;
}

.navbar.bg-factory-lab.full-background .navbar-nav > li.nav-item:hover {
  background-color: #6c087a;
}
.navbar.bg-factory-lab.full-background .navbar-nav > li.nav-item.active {
  background-color: #860a97;
}
.navbar.bg-factory-lab.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #6c087a;
}

.navbar.bg-excel.full-background .navbar-nav > li.nav-item:hover {
  background-color: #144b2d;
}
.navbar.bg-excel.full-background .navbar-nav > li.nav-item.active {
  background-color: #185d37;
}
.navbar.bg-excel.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #144b2d;
}

.navbar.bg-admin.full-background .navbar-nav > li.nav-item:hover {
  background-color: #adad00;
}
.navbar.bg-admin.full-background .navbar-nav > li.nav-item.active {
  background-color: #d6d600;
}
.navbar.bg-admin.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #adad00;
}

.navbar.bg-primary.full-background .navbar-nav > li.nav-item:hover {
  background-color: #0c3566;
}
.navbar.bg-primary.full-background .navbar-nav > li.nav-item.active {
  background-color: #0f427e;
}
.navbar.bg-primary.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #0c3566;
}

.navbar.bg-secondary.full-background .navbar-nav > li.nav-item:hover {
  background-color: #914066;
}
.navbar.bg-secondary.full-background .navbar-nav > li.nav-item.active {
  background-color: #b34f7e;
}
.navbar.bg-secondary.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #914066;
}

.navbar.bg-success.full-background .navbar-nav > li.nav-item:hover, .navbar.full-background.card_selector.active .navbar-nav > li.nav-item:hover {
  background-color: #1b722f;
}
.navbar.bg-success.full-background .navbar-nav > li.nav-item.active, .navbar.full-background.card_selector.active .navbar-nav > li.nav-item.active {
  background-color: #228c3a;
}
.navbar.bg-success.full-background .navbar-nav > li.nav-item.active:hover, .navbar.full-background.card_selector.active .navbar-nav > li.nav-item.active:hover {
  background-color: #1b722f;
}

.navbar.bg-info.full-background .navbar-nav > li.nav-item:hover {
  background-color: #106e7d;
}
.navbar.bg-info.full-background .navbar-nav > li.nav-item.active {
  background-color: #13889b;
}
.navbar.bg-info.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #106e7d;
}

.navbar.bg-warning.full-background .navbar-nav > li.nav-item:hover {
  background-color: #9d5800;
}
.navbar.bg-warning.full-background .navbar-nav > li.nav-item.active {
  background-color: #c26c00;
}
.navbar.bg-warning.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #9d5800;
}

.navbar.bg-danger.full-background .navbar-nav > li.nav-item:hover {
  background-color: #96242f;
}
.navbar.bg-danger.full-background .navbar-nav > li.nav-item.active {
  background-color: #b92d3a;
}
.navbar.bg-danger.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #96242f;
}

.navbar.bg-light.full-background .navbar-nav > li.nav-item:hover {
  background-color: #a9a9aa;
}
.navbar.bg-light.full-background .navbar-nav > li.nav-item.active {
  background-color: #d0d1d2;
}
.navbar.bg-light.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #a9a9aa;
}

.navbar.bg-dark.full-background .navbar-nav > li.nav-item:hover {
  background-color: #23272c;
}
.navbar.bg-dark.full-background .navbar-nav > li.nav-item.active {
  background-color: #2c3136;
}
.navbar.bg-dark.full-background .navbar-nav > li.nav-item.active:hover {
  background-color: #23272c;
}

.navbar.sticky-top {
  z-index: 1025;
}

@media only screen and (max-width: 576px) {
  .navbar-collapse.show .nav-text, .navbar-collapse.collapsing .nav-text {
    display: unset;
  }
  .navbar-collapse.show .nav-icon, .navbar-collapse.collapsing .nav-icon {
    width: 2rem;
    display: inline-block;
    text-align: center;
  }
}
@media only screen and (max-width: 1680px) {
  .navbar-icon-first .nav-text {
    display: none;
  }
}
@media only screen and (max-width: 1600px) {
  .navbar-icon-second .nav-text {
    display: none;
  }
}
@media only screen and (max-width: 1540px) {
  .navbar-icon-third .nav-text {
    display: none;
  }
}
@media only screen and (max-width: 1460px) {
  .navbar-icon-fourth .nav-text {
    display: none;
  }
}
@media only screen and (max-width: 1380px) {
  .navbar-icon-fifth .nav-text {
    display: none;
  }
}
@media only screen and (max-width: 1260px) {
  .navbar-icon-sixth .nav-text {
    display: none;
  }
}
@media only screen and (max-width: 1160px) {
  .navbar-icon-seventh .nav-text {
    display: none;
  }
}
.navbar .user-info {
  display: inline-flex;
  flex-direction: column;
  max-height: 35px;
  justify-content: space-around;
}
.navbar .user-info .user-info-name {
  text-align: right;
}
.navbar .user-info .user-info-organization {
  font-size: 0.8rem;
  text-align: right;
}

.dropdown-submenu {
  position: relative;
}
.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: 1em;
}
.dropdown-submenu .dropdown-menu {
  top: 0;
  left: -100%;
  width: 100%;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.navbar .navbar-stretch {
  justify-content: stretch;
  align-items: stretch;
}
.navbar .dropdown-item-wrap {
  white-space: normal;
}

.logged-in-as-someone-else {
  background-color: #d32f2f;
  line-height: 3rem;
  text-align: center;
  width: 100%;
  z-index: 99999;
  border-bottom: 4px solid #b71c1c;
  top: 0;
  height: 3rem;
  box-shadow: 0 -50px 25px 50px;
}
.logged-in-as-someone-else a {
  color: #ffebee !important;
}

.logged-in-as-someone-else-bg {
  height: 1.5rem;
}

.navbar-brand {
  display: flex;
  padding: 0.125rem;
  margin: 0 1rem 0 1rem;
}
.navbar-brand > .img-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 8rem;
}
.navbar-brand > .img-container > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.bg-factory-lab {
  background-color: rgb(159, 12, 180);
}

.img-avatar {
  padding: 0;
  height: 24px;
  width: 24px;
  object-fit: contain;
  overflow: clip;
}

.img-avatar-lg {
  padding: 0;
  height: 38px;
  width: 38px;
  object-fit: contain;
}

.dropdown-menu .dropdown-item.user-information {
  padding: 0.375rem 1.5rem;
  white-space: normal;
}
.dropdown-menu .dropdown-item.user-information h5 {
  font-size: 11px;
}
.dropdown-menu .dropdown-item.user-information h4 {
  margin-bottom: 0;
  font-size: 14px;
}
.dropdown-menu .dropdown-item.user-information h4 small {
  font-size: 11px;
}
.dropdown-menu .dropdown-item.user-information .account-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dropdown-menu .dropdown-item.user-information .account-container .account-avatar {
  margin-right: 0.75rem;
}
.dropdown-menu .dropdown-item.user-information .account-container .account-info {
  flex: 1;
}

.organization-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.organization-info .organisation-name {
  flex: 1;
  white-space: normal;
}
.organization-info .organization-logo {
  width: 64px;
  margin-left: 0.75rem;
}
.organization-info .organization-logo img {
  width: 100%;
  max-height: 64px;
  object-fit: contain;
  object-position: right center;
}

.dropdown-item {
  user-select: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .card-deck {
    width: 100%;
  }
  .card-deck .card {
    display: block;
  }
  .card-group .card {
    display: block;
  }
  .card .card-text {
    width: 100%;
  }
}
.card .sticky-top {
  z-index: 10;
}

.card .card-footer-sticky {
  z-index: 11;
}

.sticky-top {
  top: -1px;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 11;
}

.ts-dropdown {
  z-index: 1050 !important;
}

.modal-body > .questionnaire-form-container {
  margin: -1rem;
}

.card-body > .questionnaire-form-container {
  margin: -1.25rem;
}

.questionnaire-form-container {
  background-color: #dee2e6;
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
}
.questionnaire-form-container .submission {
  padding-top: 1rem;
}
.questionnaire-form-container .questionnaire-title {
  padding: 1rem 1rem 0;
  color: #495057;
}
.questionnaire-form-container .questionnaire-title h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0;
}
.questionnaire-form-container .questionnaire-title #q_icon {
  position: absolute;
  top: 0.375rem;
  right: 0.75rem;
  font-size: 1.175rem;
}
.questionnaire-form-container .questionnaire-description {
  color: #495057;
  padding-left: 1rem;
}
.questionnaire-form-container .questionnaire-extra-info {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.questionnaire-form-container .section {
  background-color: #fff;
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  padding: 1rem;
}
.questionnaire-form-container .section > .title {
  font-weight: bold;
  color: #495057;
  font-size: 1.2rem;
}
.questionnaire-form-container .question {
  margin-bottom: 7px;
}
.questionnaire-form-container .question label.title {
  font-weight: 500;
  color: #6c757d;
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  grid-auto-rows: minmax(5em, 1fr);
}
.button-grid .button-cell {
  overflow: hidden;
}
.button-grid .button-cell > * {
  height: 100%;
}
@media (min-width: 768px) {
  .button-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 992px) {
  .button-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 1200px) {
  .button-grid {
    grid-template-columns: repeat(7, 1fr);
  }
}

.row.row-hover:hover, .row-hover.craue_formflow_buttons:hover, .card .card-body .row-hover.card-row:hover {
  background-color: #e9ecef;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-selection--single .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}
.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0;
}
.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  font-family: sans-serif;
  overflow: hidden;
  word-break: keep-all;
}
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
  padding-right: 0px;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}
.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  padding: 1px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
  padding-left: 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: #f1f1f1;
  color: #333;
  outline: none;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}
.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container--default .select2-results__option--group {
  padding: 0;
}
.select2-container--default .select2-results__option--disabled {
  color: #999;
}
.select2-container--default .select2-results__option--selected {
  background-color: #ddd;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #5897fb;
  color: white;
}
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}
.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
}
.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #dee2e6;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}
.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #dee2e6;
  border-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  left: 1px;
  right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}
.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  cursor: text;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}
.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  color: #888;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none;
}
.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}
.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #dee2e6;
  outline: 0;
}
.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}
.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}
.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}
.select2-container--classic .select2-dropdown--below {
  border-top: none;
}
.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--classic .select2-results__option--group {
  padding: 0;
}
.select2-container--classic .select2-results__option--disabled {
  color: grey;
}
.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #3875d7;
  color: white;
}
.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

/**
 * We need a clone of bootstrap color-yiq mixin so we can get the same value for color
 */
.select2-container--bootstrap {
  display: block;
  /*------------------------------------*\
      #COMMON STYLES
  \*------------------------------------*/
  /**
   * Search field in the Select2 dropdown.
   */
  /**
   * No outline for all search fields - in the dropdown
   * and inline in multi Select2s.
   */
  /**
   * Adjust Select2's choices hover and selected styles to match
   * Bootstrap 4's default dropdown styles.
   *
   * @see https://getbootstrap.com/docs/4.0/components/dropdowns/
   */
  /**
   * Clear the selection.
   */
  /**
   * Address disabled Select2 styles.
   *
   * @see https://select2.github.io/examples.html#disabled
   * @see hhttps://getbootstrap.com/docs/4.0/components/forms/#disabled-forms
   */
  /*------------------------------------*\
      #DROPDOWN
  \*------------------------------------*/
  /**
   * Dropdown border color and box-shadow.
   */
  /**
   * Limit the dropdown height.
   */
  /*------------------------------------*\
      #SINGLE SELECT2
  \*------------------------------------*/
  /*------------------------------------*\
    #MULTIPLE SELECT2
  \*------------------------------------*/
  /**
   * Address Bootstrap control sizing classes
   *
   * 1. Reset Bootstrap defaults.
   * 2. Adjust the dropdown arrow button icon position.
   *
   * @see https://getbootstrap.com/docs/4.0/components/forms/#sizing
   */
  /* 1 */
  /*------------------------------------*\
    #RTL SUPPORT
  \*------------------------------------*/
}
.select2-container--bootstrap .select2-selection {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #fff;
  border: 1px solid #ced4da;
  color: #495057;
  font-size: 1rem;
  outline: 0;
}
@media (prefers-reduced-motion: reduce) {
  .select2-container--bootstrap .select2-selection {
    transition: none;
  }
}
.select2-container--bootstrap .select2-selection.form-control {
  border-radius: 0.25rem;
}
.select2-container--bootstrap .select2-search--dropdown .select2-search__field {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #fff;
  border: 1px solid #ced4da;
  color: #495057;
  font-size: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .select2-container--bootstrap .select2-search--dropdown .select2-search__field {
    transition: none;
  }
}
.select2-container--bootstrap .select2-search__field {
  outline: 0;
  /* Firefox 18- */
  /**
   * Firefox 19+
   *
   * @see http://stackoverflow.com/questions/24236240/color-for-styled-placeholder-text-is-muted-in-firefox
   */
}
.select2-container--bootstrap .select2-search__field::-webkit-input-placeholder {
  color: #6c757d;
}
.select2-container--bootstrap .select2-search__field:-moz-placeholder {
  color: #6c757d;
}
.select2-container--bootstrap .select2-search__field::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.select2-container--bootstrap .select2-search__field:-ms-input-placeholder {
  color: #6c757d;
}
.select2-container--bootstrap .select2-results__option {
  padding: 0.375rem 0.75rem;
  /**
   * Disabled results.
   *
   * @see https://select2.github.io/examples.html#disabled-results
   */
  /**
   * Hover state.
   */
  /**
   * Selected state.
   */
}
.select2-container--bootstrap .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--bootstrap .select2-results__option[aria-disabled=true] {
  color: #adb5bd;
  cursor: not-allowed;
}
.select2-container--bootstrap .select2-results__option[aria-selected=true] {
  background-color: #e9ecef;
  color: #16181b;
}
.select2-container--bootstrap .select2-results__option--highlighted[aria-selected] {
  background-color: #dee2e6;
  color: #212529;
}
.select2-container--bootstrap .select2-results__option .select2-results__option {
  padding: 0.375rem 0.75rem;
}
.select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -0.75rem;
  padding-left: 1.5rem;
}
.select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1.5rem;
  padding-left: 2.25rem;
}
.select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2.25rem;
  padding-left: 3rem;
}
.select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3rem;
  padding-left: 3.75rem;
}
.select2-container--bootstrap .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3.75rem;
  padding-left: 4.5rem;
}
.select2-container--bootstrap .select2-results__group {
  color: #6c757d;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  white-space: nowrap;
}
.select2-container--bootstrap.select2-container--focus .select2-selection, .select2-container--bootstrap.select2-container--open .select2-selection {
  border-color: #3f8ce8;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(18, 78, 150, 0.25);
}
.select2-container--bootstrap.select2-container--open {
  /**
   * Make the dropdown arrow point up while the dropdown is visible.
   */
  /**
   * Handle border radii of the container when the dropdown is showing.
   */
}
.select2-container--bootstrap.select2-container--open .select2-selection .select2-selection__arrow b {
  border-color: transparent transparent #6c757d transparent;
  border-width: 0 0.25rem 0.25rem 0.25rem;
}
.select2-container--bootstrap.select2-container--open.select2-container--below .select2-selection {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-color: transparent;
  box-shadow: none;
}
.select2-container--bootstrap.select2-container--open.select2-container--above .select2-selection {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-color: transparent;
  box-shadow: none;
}
.select2-container--bootstrap .select2-selection__clear {
  color: #6c757d;
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}
.select2-container--bootstrap .select2-selection__clear:hover {
  color: "#111";
}
.select2-container--bootstrap.select2-container--disabled .select2-selection {
  border-color: #ced4da;
  box-shadow: none;
}
.select2-container--bootstrap.select2-container--disabled .select2-selection,
.select2-container--bootstrap.select2-container--disabled .select2-search__field {
  cursor: not-allowed;
}
.select2-container--bootstrap.select2-container--disabled .select2-selection,
.select2-container--bootstrap.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  background-color: #e9ecef;
}
.select2-container--bootstrap.select2-container--disabled .select2-selection__clear,
.select2-container--bootstrap.select2-container--disabled .select2-selection--multiple .select2-selection__choice__remove {
  display: none;
}
.select2-container--bootstrap .select2-dropdown {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border-color: #3f8ce8;
  overflow-x: hidden;
  margin-top: -1px;
}
.select2-container--bootstrap .select2-dropdown--above {
  box-shadow: 0px -6px 12px rgba(0, 0, 0, 0.175);
  margin-top: 1px;
}
.select2-container--bootstrap .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--bootstrap .select2-selection--single {
  height: calc(1.5em + 0.75rem + 2px);
  line-height: 1.5;
  padding: 0.375rem 1.5rem 0.375rem 0.75rem;
  /**
   * Adjust the single Select2's dropdown arrow button appearance.
   */
}
.select2-container--bootstrap .select2-selection--single .select2-selection__arrow {
  position: absolute;
  bottom: 0;
  right: 0.75rem;
  top: 0;
  width: 0.25rem;
}
.select2-container--bootstrap .select2-selection--single .select2-selection__arrow b {
  border-color: #6c757d transparent transparent transparent;
  border-style: solid;
  border-width: 0.25rem 0.25rem 0 0.25rem;
  height: 0;
  left: 0;
  margin-left: -0.25rem;
  margin-top: -0.125rem;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--bootstrap .select2-selection--single .select2-selection__rendered {
  color: #495057;
  padding: 0;
}
.select2-container--bootstrap .select2-selection--single .select2-selection__placeholder {
  color: #6c757d;
}
.select2-container--bootstrap .select2-selection--multiple {
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0;
  height: auto;
  /**
   * Make Multi Select2's choices match Bootstrap 4's default button styles.
   */
  /**
   * Minus 2px borders.
   */
  /**
   * Clear the selection.
   */
}
.select2-container--bootstrap .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  display: block;
  line-height: 1.5;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container--bootstrap .select2-selection--multiple .select2-selection__placeholder {
  color: #6c757d;
  float: left;
  margin-top: 5px;
}
.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
  color: #495057;
  background: #e9ecef;
  border: 1px solid #adb5bd;
  border-radius: 0.25rem;
  cursor: default;
  float: left;
  margin: calc(0.375rem - 1px) 0 0 0.375rem;
  padding: 0 0.375rem;
}
.select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
  background: transparent;
  padding: 0 0.75rem;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: 1.5;
  margin: -1px 0;
  min-width: 5em;
}
.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove {
  color: #6c757d;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 0.1875rem;
}
.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: "#111";
}
.select2-container--bootstrap .select2-selection--multiple .select2-selection__clear {
  margin-top: 0.375rem;
}
.select2-container--bootstrap .select2-selection--single.form-control-sm, .input-group-sm .select2-container--bootstrap .select2-selection--single, .form-group-sm .select2-container--bootstrap .select2-selection--single {
  border-radius: 0.2rem;
  font-size: 0.875rem;
  height: calc(1.5em + 0.5rem + 2px);
  line-height: 1.5;
  padding: 0.25rem 1.25rem 0.25rem 0.5rem;
  /* 2 */
}
.select2-container--bootstrap .select2-selection--single.form-control-sm .select2-selection__arrow b, .input-group-sm .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b, .form-group-sm .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b {
  margin-left: -0.25rem;
}
.select2-container--bootstrap .select2-selection--multiple.form-control-sm, .input-group-sm .select2-container--bootstrap .select2-selection--multiple, .form-group-sm .select2-container--bootstrap .select2-selection--multiple {
  border-radius: 0.2rem;
  min-height: calc(1.5em + 0.5rem + 2px);
}
.select2-container--bootstrap .select2-selection--multiple.form-control-sm .select2-selection__choice, .input-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice, .form-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
  font-size: 0.875rem;
  line-height: 1.5;
  margin: calc(0.25rem - 1px) 0 0 0.25rem;
  padding: 0 0.25rem;
}
.select2-container--bootstrap .select2-selection--multiple.form-control-sm .select2-search--inline .select2-search__field, .input-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field, .form-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 0 0.5rem;
  font-size: 0.875rem;
  height: calc(1.5em + 0.5rem + 2px);
  line-height: 1.5;
}
.select2-container--bootstrap .select2-selection--multiple.form-control-sm .select2-selection__clear, .input-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear, .form-group-sm .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear {
  margin-top: 0.25rem;
}
.select2-container--bootstrap .select2-selection--single.form-control-lg, .input-group-lg .select2-container--bootstrap .select2-selection--single, .form-group-lg .select2-container--bootstrap .select2-selection--single {
  border-radius: 0.3rem;
  font-size: 1.25rem;
  height: calc(1.5em + 1rem + 2px);
  line-height: 1.5;
  padding: 0.5rem 1.9375rem 0.5rem 1rem;
  /* 1 */
}
.select2-container--bootstrap .select2-selection--single.form-control-lg .select2-selection__arrow, .input-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow, .form-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow {
  width: 0.3125rem;
}
.select2-container--bootstrap .select2-selection--single.form-control-lg .select2-selection__arrow b, .input-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b, .form-group-lg .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b {
  border-width: 0.3125rem 0.3125rem 0 0.3125rem;
  margin-left: -0.3125rem;
  margin-left: -0.5rem;
  margin-top: -0.15625rem;
}
.select2-container--bootstrap .select2-selection--multiple.form-control-lg, .input-group-lg .select2-container--bootstrap .select2-selection--multiple, .form-group-lg .select2-container--bootstrap .select2-selection--multiple {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: 0.3rem;
}
.select2-container--bootstrap .select2-selection--multiple.form-control-lg .select2-selection__choice, .input-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice, .form-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  margin: calc(0.5rem - 1px) 0 0 0.5rem;
  padding: 0 0.5rem;
}
.select2-container--bootstrap .select2-selection--multiple.form-control-lg .select2-search--inline .select2-search__field, .input-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field, .form-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 0 1rem;
  font-size: 1.25rem;
  height: calc(1.5em + 1rem + 2px);
  line-height: 1.5;
}
.select2-container--bootstrap .select2-selection--multiple.form-control-lg .select2-selection__clear, .input-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear, .form-group-lg .select2-container--bootstrap .select2-selection--multiple .select2-selection__clear {
  margin-top: 0.5rem;
}
.select2-container--bootstrap .select2-selection.form-control-lg.select2-container--open .select2-selection--single {
  /**
   * Make the dropdown arrow point up while the dropdown is visible.
   */
}
.select2-container--bootstrap .select2-selection.form-control-lg.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #6c757d transparent;
  border-width: 0 0.3125rem 0.3125rem 0.3125rem;
}
.input-group-lg .select2-container--bootstrap .select2-selection.select2-container--open .select2-selection--single {
  /**
   * Make the dropdown arrow point up while the dropdown is visible.
   */
}
.input-group-lg .select2-container--bootstrap .select2-selection.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #6c757d transparent;
  border-width: 0 0.3125rem 0.3125rem 0.3125rem;
}
.select2-container--bootstrap[dir=rtl] {
  /**
   * Single Select2
   *
   * 1. Makes sure that .select2-selection__placeholder is positioned
   *    correctly.
   */
  /**
   * Multiple Select2
   */
}
.select2-container--bootstrap[dir=rtl] .select2-selection--single {
  padding-left: 1.5rem;
  padding-right: 0.75rem;
}
.select2-container--bootstrap[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 0;
  padding-left: 0;
  text-align: right; /* 1 */
}
.select2-container--bootstrap[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--bootstrap[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 0.75rem;
  right: auto;
}
.select2-container--bootstrap[dir=rtl] .select2-selection--single .select2-selection__arrow b {
  margin-left: 0;
}
.select2-container--bootstrap[dir=rtl] .select2-selection--multiple .select2-selection__choice,
.select2-container--bootstrap[dir=rtl] .select2-selection--multiple .select2-selection__placeholder,
.select2-container--bootstrap[dir=rtl] .select2-selection--multiple .select2-search--inline {
  float: right;
}
.select2-container--bootstrap[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 0;
  margin-right: 0.375rem;
}
.select2-container--bootstrap[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}
.select2-container--bootstrap .select2-dropdown[dir=rtl] .select2-results__options {
  text-align: right;
}

/*------------------------------------*\
  #ADDITIONAL GOODIES
\*------------------------------------*/
/**
 * Address Bootstrap's validation states
 *
 * If a Select2 widget parent has one of Bootstrap's validation state modifier
 * classes, adjust Select2's border colors and focus states accordingly.
 * You may apply said classes to the Select2 dropdown (body > .select2-container)
 * via JavaScript match Bootstraps' to make its styles match.
 *
 * @see https://getbootstrap.com/docs/4.0/components/forms/#validation
 */
.is-valid .select2-dropdown,
.is-valid .select2-selection {
  border-color: #28a745;
}
.is-valid .select2-container--focus .select2-selection,
.is-valid .select2-container--open .select2-selection {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #5dd879;
  border-color: #1e7e34;
}
.is-valid .select2-container--focus .select2-selection:focus,
.is-valid .select2-container--open .select2-selection:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.is-valid.select2-drop-active {
  border-color: #1e7e34;
}
.is-valid.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #1e7e34;
}

.is-invalid .select2-dropdown,
.is-invalid .select2-selection {
  border-color: #dc3545;
}
.is-invalid .select2-container--focus .select2-selection,
.is-invalid .select2-container--open .select2-selection {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #eb8c95;
  border-color: #bd2130;
}
.is-invalid .select2-container--focus .select2-selection:focus,
.is-invalid .select2-container--open .select2-selection:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.is-invalid.select2-drop-active {
  border-color: #bd2130;
}
.is-invalid.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #bd2130;
}

/* Validation classes on parent element. Preserved Bootstrap 3 validation classes */
.has-warning .select2-dropdown,
.has-warning .select2-selection {
  border-color: #e78100;
}
.has-warning .select2-container--focus .select2-selection,
.has-warning .select2-container--open .select2-selection {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffb14e;
  border-color: #b46500;
}
.has-warning .select2-container--focus .select2-selection:focus,
.has-warning .select2-container--open .select2-selection:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 129, 0, 0.25);
}
.has-warning.select2-drop-active {
  border-color: #b46500;
}
.has-warning.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #b46500;
}

.has-error .select2-dropdown,
.has-error .select2-selection {
  border-color: #dc3545;
}
.has-error .select2-container--focus .select2-selection,
.has-error .select2-container--open .select2-selection {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #eb8c95;
  border-color: #bd2130;
}
.has-error .select2-container--focus .select2-selection:focus,
.has-error .select2-container--open .select2-selection:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.has-error.select2-drop-active {
  border-color: #bd2130;
}
.has-error.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #bd2130;
}

.has-success .select2-dropdown,
.has-success .select2-selection {
  border-color: #28a745;
}
.has-success .select2-container--focus .select2-selection,
.has-success .select2-container--open .select2-selection {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #5dd879;
  border-color: #1e7e34;
}
.has-success .select2-container--focus .select2-selection:focus,
.has-success .select2-container--open .select2-selection:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.has-success.select2-drop-active {
  border-color: #1e7e34;
}
.has-success.select2-drop-active.select2-drop.select2-drop-above {
  border-top-color: #1e7e34;
}

/**
 * Select2 widgets in Bootstrap Input Groups
 *
 * @see https://getbootstrap.com/docs/4.0/components/input-group/
 * @see https://github.com/twbs/bootstrap/blob/v4.4.1/scss/_input-group.scss
 */
/**
 * Reset rounded corners
 */
.input-group > .select2-hidden-accessible:not(:first-child) + .select2-container--bootstrap > .selection > .select2-selection, .input-group > .select2-hidden-accessible:not(:first-child) + .select2-container--bootstrap > .selection > .select2-selection.form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .select2-hidden-accessible + .select2-container--bootstrap:not(:last-child) > .selection > .select2-selection,
.input-group > .select2-hidden-accessible + .select2-container--bootstrap:not(:last-child) > .selection > .select2-selection.form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .select2-container--bootstrap {
  flex: 1 1 auto;
  position: relative;
  z-index: 2;
  width: 1%;
  margin-bottom: 0;
  /**
   * Adjust z-index like Bootstrap does to show the focus-box-shadow
   * above appended buttons in .input-group and .form-group.
   */
  /**
   * Adjust alignment of Bootstrap buttons in Bootstrap Input Groups to address
   * Multi Select2's height which - depending on how many elements have been selected -
   * may grow taller than its initial size.
   *
   * @see https://github.com/twbs/bootstrap/blob/v4.4.1/scss/_input-group.scss
   */
}
.input-group > .select2-container--bootstrap > .selection {
  display: flex;
  flex: 1 1 auto;
}
.input-group > .select2-container--bootstrap > .selection > .select2-selection.form-control {
  float: none;
}
.input-group > .select2-container--bootstrap.select2-container--open, .input-group > .select2-container--bootstrap.select2-container--focus {
  z-index: 3;
}
.input-group > .select2-container--bootstrap,
.input-group > .select2-container--bootstrap .input-group-append,
.input-group > .select2-container--bootstrap .input-group-prepend,
.input-group > .select2-container--bootstrap .input-group-append .btn,
.input-group > .select2-container--bootstrap .input-group-prepend .btn {
  vertical-align: top;
}

/**
 * Temporary fix for https://github.com/select2/select2-bootstrap-theme/issues/9
 *
 * Provides `!important` for certain properties of the class applied to the
 * original `<select>` element to hide it.
 *
 * @see https://github.com/select2/select2/pull/3301
 * @see https://github.com/fk/select2/commit/31830c7b32cb3d8e1b12d5b434dee40a6e753ada
 */
.form-control.select2-hidden-accessible {
  position: absolute !important;
  width: 1px !important;
}

/**
 * Display override for inline forms
 */
@media (min-width: 576px) {
  .form-inline .select2-container--bootstrap {
    display: inline-block;
  }
}

.select2-dropdown {
  z-index: 1056;
}

.was-validated .select2:valid + .select2 .select2-selection, .select2.is-valid + .select2 .select2-selection {
  border-color: #28a745;
  padding-right: calc(calc(1.5em + 0.75rem) + calc(0.75em + 0.375rem));
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(calc(0.375em + 0.1875rem) + calc(0.75em + 0.375rem)) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .select2:valid + .select2 .select2-selection:focus, .select2.is-valid + .select2 .select2-selection:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .select2:invalid + .select2 .select2-selection, .select2.is-invalid + .select2 .select2-selection {
  border-color: #dc3545;
  padding-right: calc(calc(1.5em + 0.75rem) + calc(0.75em + 0.375rem));
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(calc(0.375em + 0.1875rem) + calc(0.75em + 0.375rem)) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .select2:invalid + .select2 .select2-selection:focus, .select2.is-invalid + .select2 .select2-selection:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.select2-container--bootstrap .select2-results__option--selected {
  background-color: #e9ecef;
  color: #16181b;
}

.select2-results .select2-results__options {
  overscroll-behavior: contain;
}

.select2-container .select2-results .select2-results__options--nested .select2-results__option {
  padding-left: 1.5rem;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove {
  border: 0;
  background-color: transparent;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.select2-container--bootstrap .select2-results__option[aria-disabled=true] {
  background-color: #e9ecef;
}

.card > .table.table-bordered {
  border-left: 0;
  border-right: 0;
}
.card > .table.table-bordered th:first-child, .card > .table.table-bordered td:first-child {
  border-left: 0;
}
.card > .table.table-bordered th:last-child, .card > .table.table-bordered td:last-child {
  border-right: 0;
}

.table > tbody > tr > td h1:last-child, .table > tbody > tr > td h2:last-child, .table > tbody > tr > td h3:last-child, .table > tbody > tr > td h4:last-child, .table > tbody > tr > td h5:last-child, .table > tbody > tr > td h6:last-child {
  margin-bottom: 0;
  margin-top: 0.375rem;
}

table tr.vert-align > td {
  vertical-align: middle;
}
table td.vert-align {
  vertical-align: middle;
}
table td.primary-dark, table tr.primary-dark {
  background-color: #d1d7e0;
  color: #0c1c33;
}
table td.default, table tr.default {
  background-color: white;
  color: #858585;
}
table td.add, table tr.add {
  background-color: #d3edd8;
  color: #115620;
}
table td.delete, table tr.delete {
  background-color: #f6e0e4;
  color: #6e353f;
}
table td.nav, table tr.nav {
  background-color: #e2e1e1;
  color: #383838;
}
table td.measurement-existing, table tr.measurement-existing {
  background-color: #def1de;
  color: #306030;
}
table td.installation-existing, table tr.installation-existing {
  background-color: #ffded3;
  color: #853012;
}
table td.installation, table tr.installation {
  background-color: #fae6cc;
  color: #784300;
}
table td.gray, table tr.gray {
  background-color: #d6d8d9;
  color: #1b1e21;
}
table td.gray-400, table tr.gray-400 {
  background-color: #f5f6f8;
  color: #6b6e71;
}
table td.factory-lab, table tr.factory-lab {
  background-color: #eccef0;
  color: #53065e;
}
table td.excel, table tr.excel {
  background-color: #d2e2d9;
  color: #0f3a22;
}
table td.admin, table tr.admin {
  background-color: #ffffcc;
  color: #858500;
}
table td.primary, table tr.primary {
  background-color: #d0dcea;
  color: #09294e;
}
table td.secondary, table tr.secondary {
  background-color: #f7dfea;
  color: #6f314e;
}
table td.success, table tr.success {
  background-color: #d4edda;
  color: #155724;
}
table td.info, table tr.info {
  background-color: #d1ecf1;
  color: #0c5460;
}
table td.warning, table tr.warning {
  background-color: #fae6cc;
  color: #784300;
}
table td.danger, table tr.danger {
  background-color: #f8d7da;
  color: #721c24;
}
table td.light, table tr.light {
  background-color: #fefefe;
  color: #818182;
}
table td.dark, table tr.dark {
  background-color: #d6d8d9;
  color: #1b1e21;
}
table.table-striped tr.primary-dark:nth-of-type(odd) {
  background-color: #bdd1f0;
  color: #212529;
}
table.table-striped tr.default:nth-of-type(odd) {
  background-color: #f2f2f2;
  color: #212529;
}
table.table-striped tr.add:nth-of-type(odd) {
  background-color: #c5f3cf;
  color: #212529;
}
table.table-striped tr.delete:nth-of-type(odd) {
  background-color: #f3d5db;
  color: #212529;
}
table.table-striped tr.nav:nth-of-type(odd) {
  background-color: #dddddd;
  color: #212529;
}
table.table-striped tr.measurement-existing:nth-of-type(odd) {
  background-color: #d6edd6;
  color: #212529;
}
table.table-striped tr.installation-existing:nth-of-type(odd) {
  background-color: #ffd5c6;
  color: #212529;
}
table.table-striped tr.installation:nth-of-type(odd) {
  background-color: #ffe2be;
  color: #212529;
}
table.table-striped tr.gray:nth-of-type(odd) {
  background-color: #d2d6da;
  color: #212529;
}
table.table-striped tr.gray-400:nth-of-type(odd) {
  background-color: #e9ecef;
  color: #212529;
}
table.table-striped tr.factory-lab:nth-of-type(odd) {
  background-color: #f3bdfb;
  color: #212529;
}
table.table-striped tr.excel:nth-of-type(odd) {
  background-color: #c1efd6;
  color: #212529;
}
table.table-striped tr.admin:nth-of-type(odd) {
  background-color: #ffffc1;
  color: #212529;
}
table.table-striped tr.primary:nth-of-type(odd) {
  background-color: #bdd7f7;
  color: #212529;
}
table.table-striped tr.secondary:nth-of-type(odd) {
  background-color: #f4d4e3;
  color: #212529;
}
table.table-striped tr.success:nth-of-type(odd) {
  background-color: #c8f2d1;
  color: #212529;
}
table.table-striped tr.info:nth-of-type(odd) {
  background-color: #c2f0f7;
  color: #212529;
}
table.table-striped tr.warning:nth-of-type(odd) {
  background-color: #ffe2be;
  color: #212529;
}
table.table-striped tr.danger:nth-of-type(odd) {
  background-color: #f6ccd0;
  color: #212529;
}
table.table-striped tr.light:nth-of-type(odd) {
  background-color: #eff1f4;
  color: #212529;
}
table.table-striped tr.dark:nth-of-type(odd) {
  background-color: #d2d6da;
  color: #212529;
}
table.table-hover tr:hover.primary-dark, table.table-hover tr:hover td.primary-dark {
  background-color: #abc5ec;
  color: #212529;
}
table.table-hover tr:hover.default, table.table-hover tr:hover td.default {
  background-color: #e6e6e6;
  color: #212529;
}
table.table-hover tr:hover.add, table.table-hover tr:hover td.add {
  background-color: #b1f0bf;
  color: #212529;
}
table.table-hover tr:hover.delete, table.table-hover tr:hover td.delete {
  background-color: #eec3ca;
  color: #212529;
}
table.table-hover tr:hover.nav, table.table-hover tr:hover td.nav {
  background-color: #d2d1d1;
  color: #212529;
}
table.table-hover tr:hover.measurement-existing, table.table-hover tr:hover td.measurement-existing {
  background-color: #c5e6c5;
  color: #212529;
}
table.table-hover tr:hover.installation-existing, table.table-hover tr:hover td.installation-existing {
  background-color: #ffc3ae;
  color: #212529;
}
table.table-hover tr:hover.installation, table.table-hover tr:hover td.installation {
  background-color: #ffd8a6;
  color: #212529;
}
table.table-hover tr:hover.gray, table.table-hover tr:hover td.gray {
  background-color: #c6cbd0;
  color: #212529;
}
table.table-hover tr:hover.gray-400, table.table-hover tr:hover td.gray-400 {
  background-color: #dbe0e4;
  color: #212529;
}
table.table-hover tr:hover.factory-lab, table.table-hover tr:hover td.factory-lab {
  background-color: #efa7f9;
  color: #212529;
}
table.table-hover tr:hover.excel, table.table-hover tr:hover td.excel {
  background-color: #afeaca;
  color: #212529;
}
table.table-hover tr:hover.admin, table.table-hover tr:hover td.admin {
  background-color: #ffffaa;
  color: #212529;
}
table.table-hover tr:hover.primary, table.table-hover tr:hover td.primary {
  background-color: #a8cbf5;
  color: #212529;
}
table.table-hover tr:hover.secondary, table.table-hover tr:hover td.secondary {
  background-color: #efc1d6;
  color: #212529;
}
table.table-hover tr:hover.success, table.table-hover tr:hover td.success {
  background-color: #b5edc2;
  color: #212529;
}
table.table-hover tr:hover.info, table.table-hover tr:hover td.info {
  background-color: #adebf5;
  color: #212529;
}
table.table-hover tr:hover.warning, table.table-hover tr:hover td.warning {
  background-color: #ffd8a6;
  color: #212529;
}
table.table-hover tr:hover.danger, table.table-hover tr:hover td.danger {
  background-color: #f3b8be;
  color: #212529;
}
table.table-hover tr:hover.light, table.table-hover tr:hover td.light {
  background-color: #e0e5e9;
  color: #212529;
}
table.table-hover tr:hover.dark, table.table-hover tr:hover td.dark {
  background-color: #c6cbd0;
  color: #212529;
}

.table-md td, .table-md th {
  padding: 0.6rem;
}

td > ul:not(.ui-autocomplete), td > span.value > ul:not(.ui-autocomplete) {
  padding-inline-start: 1rem;
}
td > ul:last-child, td > span.value > ul:last-child {
  margin-bottom: 0;
}

td > dl, td > span.value > dl {
  margin-bottom: 0;
}

.table.table-vertical-align-center tbody th, .table.table-vertical-align-center td {
  vertical-align: middle;
}

th.sorting_asc, th.sorting_desc {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-responsive-shadow {
  pointer-events: none;
}
.table-responsive-shadow.shadow-before {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0;
  transition: opacity 325ms ease-in-out;
}
.table-responsive-shadow.shadow-before.show {
  opacity: 1;
}
.table-responsive-shadow.shadow-after {
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0;
  transition: opacity 325ms ease-in-out;
}
.table-responsive-shadow.shadow-after.show {
  opacity: 1;
}

.tasks-table td .float-right + .float-right {
  margin-right: 0.175rem;
}

.table th[data-header=last-online]:not(.loading) .fa-spinner {
  display: none;
}
.table th[data-header=last-online].loading {
  pointer-events: none;
  position: relative;
}
.table th[data-header=last-online].loading .fa-spinner {
  position: absolute;
  right: 10px;
  top: 12px;
}

.text-word-wrap {
  white-space: normal;
  word-break: normal;
}

blockquote {
  border-left: 0.25rem solid #124e96;
  padding-left: 0.875rem;
}
blockquote cite {
  font-size: 1rem;
}

.text-gray-100 {
  color: #f8f9fa;
}
.text-gray-200 {
  color: #e9ecef;
}
.text-gray-300 {
  color: #dee2e6;
}
.text-gray-400 {
  color: #ced4da;
}
.text-gray-500 {
  color: #adb5bd;
}
.text-gray-600 {
  color: #6c757d;
}
.text-gray-700 {
  color: #495057;
}
.text-gray-800 {
  color: #343a40;
}
.text-gray-900 {
  color: #212529;
}

.fs-1 {
  font-size: 2.5rem;
}
.fs-2 {
  font-size: 2rem;
}
.fs-3 {
  font-size: 1.75rem;
}
.fs-4 {
  font-size: 1.5rem;
}
.fs-5 {
  font-size: 1.25rem;
}
.fs-6 {
  font-size: 1rem;
}
.fs-7 {
  font-size: 0.875rem;
}

.tokenfield-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  padding: 0 0.375rem 0.375rem 0.375rem;
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #fff;
}
.tokenfield-container:not(.disabled) {
  cursor: text;
}
.tokenfield-container.disabled {
  color: #495057;
  background-color: #e9ecef;
}
.tokenfield-container.disabled .token {
  cursor: not-allowed;
  opacity: 0.65;
}
.tokenfield-container.focussed {
  color: #495057;
  background-color: #fff;
  border-color: #3f8ce8;
  box-shadow: 0 0 0 0.2rem rgba(18, 78, 150, 0.25);
}
.tokenfield-container .token {
  margin-top: 0.375rem;
  cursor: pointer;
  margin-right: 0.375rem;
  background-color: #e9ecef;
  padding: 0 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  transition-property: background-color;
}
.tokenfield-container .token::after {
  content: "×";
  margin-left: 0.25rem;
}
.tokenfield-container:not(.disabled) .token:hover {
  background-color: #dee2e6;
}
.tokenfield-container input {
  flex: 1;
  min-width: 2em;
}
.tokenfield-container input, .tokenfield-container input:focus {
  margin-top: 0.375rem;
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  outline: 0;
}

.widget .widget-row .widget-name-column {
  text-decoration: none;
  word-break: break-all;
}
.widget .widget-row .download-icon {
  display: none;
  text-align: center;
}
.widget .widget-row .download-icon .fa {
  transform: scale(1.375);
}
.widget .widget-row:hover {
  cursor: pointer;
}
.widget .widget-row:hover .download-link {
  text-decoration: none;
}
.widget .widget-row:hover .date {
  display: none;
}
.widget .widget-row:hover .download-icon {
  display: block;
}
.widget .card-footer {
  background: transparent;
}
.widget .widget-number-large {
  text-align: center;
  font-size: 1.875rem;
}
.widget.client-organization-logo-widget {
  align-content: center;
}
.widget.client-organization-logo-widget .image-thumbnail-anchor {
  pointer-events: none;
}
.widget.client-organization-logo-widget .image-thumbnail-anchor img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.loader-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  background-color: rgba(244, 244, 244, 0.3333333333);
  backdrop-filter: blur(3px);
  z-index: 100;
  align-items: center;
}

.user-card {
  display: inline-grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  background-color: #e9ecef;
  border: 1px solid #dee2e6;
  border-radius: 10rem;
  gap: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.user-card img {
  padding: 0;
  min-width: 24px;
  height: 24px;
  width: 24px;
  object-fit: contain;
  overflow: clip;
}

.bg-info-light {
  background-color: #bee5eb;
}

html {
  font-size: 13px;
  scroll-behavior: smooth;
}

.border-size-0 {
  border-width: 1px !important;
}

.border-size-1 {
  border-width: 2px !important;
}

.border-size-2 {
  border-width: 3px !important;
}

.border-size-3 {
  border-width: 5px !important;
}

.border-size-4 {
  border-width: 8px !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.375rem !important;
}

.gap-2 {
  gap: 0.75rem !important;
}

.gap-3 {
  gap: 1.5rem !important;
}

.gap-4 {
  gap: 2.25rem !important;
}

.gap-5 {
  gap: 4.5rem !important;
}

.todo-icon {
  position: relative;
}
.todo-icon .special {
  right: -9px;
  top: -4px;
  position: absolute;
  padding: 0.125rem 0.375rem;
  border-radius: 10px;
}

.col {
  min-width: 0;
}
.col.col-responsive, .col.col-responsive-small {
  flex-basis: unset;
  flex-grow: 0;
  flex-shrink: 0;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .col.col-responsive, .col.col-responsive-small {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .col.col-responsive {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col.col-responsive-small {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
}
@media (min-width: 576px) {
  .col.col-responsive, .col.col-responsive-small {
    flex-basis: unset;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .col.col-responsive {
    width: 230.4px;
  }
  .col.col-responsive-small {
    width: 172.8px;
  }
}
@media (min-width: 576px) and (-ms-high-contrast: none), (min-width: 576px) and (-ms-high-contrast: active) {
  .col.col-responsive, .col.col-responsive-small {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .col.col-responsive {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col.col-responsive-small {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
}
@media (min-width: 768px) {
  .col.col-responsive, .col.col-responsive-small {
    flex-basis: unset;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .col.col-responsive {
    width: 307.2px;
  }
  .col.col-responsive-small {
    width: 230.4px;
  }
}
@media (min-width: 768px) and (-ms-high-contrast: none), (min-width: 768px) and (-ms-high-contrast: active) {
  .col.col-responsive, .col.col-responsive-small {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .col.col-responsive {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col.col-responsive-small {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
}
@media (min-width: 992px) {
  .col.col-responsive, .col.col-responsive-small {
    flex-basis: unset;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .col.col-responsive {
    width: 396.8px;
  }
  .col.col-responsive-small {
    width: 297.6px;
  }
}
@media (min-width: 992px) and (-ms-high-contrast: none), (min-width: 992px) and (-ms-high-contrast: active) {
  .col.col-responsive, .col.col-responsive-small {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .col.col-responsive {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col.col-responsive-small {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
}
@media (min-width: 1200px) {
  .col.col-responsive, .col.col-responsive-small {
    flex-basis: unset;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .col.col-responsive {
    width: 480px;
  }
  .col.col-responsive-small {
    width: 360px;
  }
}
@media (min-width: 1200px) and (-ms-high-contrast: none), (min-width: 1200px) and (-ms-high-contrast: active) {
  .col.col-responsive, .col.col-responsive-small {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .col.col-responsive {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col.col-responsive-small {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
}

.card.text-primary-dark > .table {
  color: #173563;
}

.card.text-default > .table {
  color: #FFF;
}

.card.text-add > .table {
  color: #21A63D;
}

.card.text-delete > .table {
  color: #D4667A;
}

.card.text-nav > .table {
  color: #6c6b6b;
}

.card.text-measurement-existing > .table {
  color: #5cb85c;
}

.card.text-installation-existing > .table {
  color: #ff5c22;
}

.card.text-installation > .table {
  color: #e78100;
}

.card.text-gray > .table {
  color: #343a40;
}

.card.text-gray-400 > .table {
  color: #ced4da;
}

.card.text-factory-lab > .table {
  color: rgb(159, 12, 180);
}

.card.text-excel > .table {
  color: #1d6f42;
}

.card.text-admin > .table {
  color: #FFFF00;
}

.card.text-primary > .table {
  color: #124e96;
}

.card.text-secondary > .table {
  color: #d55e96;
}

.card.text-success > .table {
  color: #28a745;
}

.card.text-info > .table {
  color: #17a2b8;
}

.card.text-warning > .table {
  color: #e78100;
}

.card.text-danger > .table {
  color: #dc3545;
}

.card.text-light > .table {
  color: #f8f9fa;
}

.card.text-dark > .table {
  color: #343a40;
}

.page-title {
  font-size: 1rem;
  margin-bottom: 0;
}

#locationContainer a.list-group-item .location-icon, #locationContainer a.list-group-subitem .location-icon {
  display: inline-block;
  height: 1em;
  width: 1em;
  margin-right: 5px;
}
#locationContainer a.list-group-item .location-icon img, #locationContainer a.list-group-subitem .location-icon img {
  max-width: 100%;
  max-height: 100%;
}

.btn-default {
  border-color: #d9d9d9;
}
.btn-default.disabled, .btn-default:disabled {
  border-color: #d9d9d9;
}

.btn-transparent {
  background-color: transparent;
  color: #124e96;
  outline-width: 1px;
  outline-offset: 0;
}
.btn-transparent:active, .btn-transparent:hover {
  color: #124e96;
}
.btn-transparent.active {
  background-color: #dee6f0;
  box-shadow: none !important;
}
.btn-transparent:hover {
  background-color: #e9edf4;
}

.row > hr, .craue_formflow_buttons > hr, .card .card-body .card-row > hr {
  flex: 1;
}

.form-group > label, .form-group.row > div > label, .form-group.craue_formflow_buttons > div > label, .card .card-body .form-group.card-row > div > label {
  font-weight: 500;
}
.form-group.row > div > label, .form-group.craue_formflow_buttons > div > label, .card .card-body .form-group.card-row > div > label {
  line-height: calc(1.5em + 0.75rem + 2px);
}
.form-group label ~ .help-block {
  margin-bottom: calc(0.375em + 0.1875rem);
}

textarea.no-resize-horizontal {
  resize: vertical;
}

.card_selector {
  min-width: 15rem;
  max-width: 25rem;
}
.card_selector.in_active {
  background-color: #ced4da;
}
.card_selector.active > .table {
  color: #fff;
}

caption {
  caption-side: top;
}

.card-header > .btn, .card-header > .btn-group, .card-header > .row > div > .btn, .card .card-body .card-header > .card-row > div > .btn, .card-header > .craue_formflow_buttons > div > .btn, .card-header > .row > div > .btn-group, .card .card-body .card-header > .card-row > div > .btn-group, .card-header > .craue_formflow_buttons > div > .btn-group, .card-header > div.float-right {
  margin-top: -1.5rem;
  margin-bottom: -0.75rem;
}
.card-header > .btn.float-right, .card-header > .btn.float-left, .card-header > .btn-group.float-right, .card-header > .btn-group.float-left, .card-header > .row > div > .btn.float-right, .card .card-body .card-header > .card-row > div > .btn.float-right, .card-header > .craue_formflow_buttons > div > .btn.float-right, .card-header > .row > div > .btn.float-left, .card .card-body .card-header > .card-row > div > .btn.float-left, .card-header > .craue_formflow_buttons > div > .btn.float-left, .card-header > .row > div > .btn-group.float-right, .card .card-body .card-header > .card-row > div > .btn-group.float-right, .card-header > .craue_formflow_buttons > div > .btn-group.float-right, .card-header > .row > div > .btn-group.float-left, .card .card-body .card-header > .card-row > div > .btn-group.float-left, .card-header > .craue_formflow_buttons > div > .btn-group.float-left, .card-header > div.float-right.float-right, .card-header > div.float-right.float-left {
  margin-top: -0.375rem;
  margin-bottom: -0.375rem;
}

body.no-animations * {
  animation-duration: 0s !important;
  transition: all 0s !important;
}

.list-group-subitem {
  padding-left: 2.25rem;
}

.list-group-item.list-group-item-header, .list-group-item-header.list-group-subitem {
  background-color: rgba(0, 0, 0, 0.042);
}

[data-toggle=collapse] {
  cursor: pointer;
}

.card-body hr.flush {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.card-body > div > div.table-responsive.flush {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  width: calc(100% + 1.25rem * 2);
}

ul.list-flush, ol.list-flush {
  padding-inline-start: 1.5rem;
}

.input-group-inline {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group-inline .input-group-prepend, .input-group-inline .input-group-append {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2rem;
}
.input-group-inline .input-group-prepend .input-group-text, .input-group-inline .input-group-append .input-group-text {
  background-color: transparent;
  border: 0;
  color: #6c757d;
}
.input-group-inline .input-group-prepend {
  left: 0;
}
.input-group-inline .input-group-append {
  right: 0;
}
.input-group-inline .input-group-prepend ~ .form-control {
  padding-left: 2.5rem;
}
.input-group-inline .form-control ~ .input-group-append {
  padding-right: 2.5rem;
}

.list-group-item.value-heuristic-1, .value-heuristic-1.list-group-subitem, .list-group-item.value-heuristic-2, .value-heuristic-2.list-group-subitem, .list-group-item.value-heuristic-3, .value-heuristic-3.list-group-subitem, .list-group-item.value-heuristic-4, .value-heuristic-4.list-group-subitem, .list-group-item.value-distance, .value-distance.list-group-subitem {
  background-color: #fae6cc;
  border-color: #f8dcb8;
  color: #721c24;
}

.highcharts-container {
  z-index: auto !important;
  overflow: visible !important;
}

table.table.table-responsive {
  position: relative;
}
table.table.table-responsive::after {
  content: "Gebruik de table-responsive op een parent div van deze, niet op de tabel zelf ;)";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2rem;
  font-size: 1.5rem;
  color: #fff;
  backdrop-filter: blur(10px);
  background-color: rgba(213, 94, 150, 0.7);
}

body.extend_scripts::after {
  content: 'When extending the "scripts" tag, also make sure to include the "parent()" call!';
  color: #fff;
  backdrop-filter: blur(10px);
  background-color: rgba(18, 78, 150, 0.7);
  position: fixed;
  text-align: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  font-size: 3rem;
  line-height: 30rem;
}

.form-check.abc-checkbox {
  margin-bottom: calc(0.375em + 0.1875rem);
}
.list-group-item .form-check.abc-checkbox, .list-group-subitem .form-check.abc-checkbox {
  margin-bottom: 0;
}
.list-group-item .form-check.abc-checkbox label, .list-group-subitem .form-check.abc-checkbox label {
  padding-left: 1rem;
}

.explanation {
  position: relative;
  bottom: 0.375rem;
  font-size: 0.75rem;
}

.navbar-location .address-container {
  padding-right: 1.875rem;
}
.navbar-location .ra-location-icon-container {
  position: absolute;
  right: 0;
  height: 1.875rem;
  width: 1.875rem;
}

img.ra-location-icon {
  height: 1.875rem;
  width: 1.875rem;
}

#drop_shipment_suggestedItems_container, #drop_shipment_suggestedItems_container .card {
  margin: 0 0 1rem;
}

div[data-toggle=collapse] .fa.fa-chevron-down {
  transition: height 0.35s ease;
  transition-property: transform;
  transform: rotate(180deg);
}
div[data-toggle=collapse].collapsed .fa.fa-chevron-down {
  transform: rotate(0deg);
  filter: none;
}

.sky-light-bg {
  background: var(--Utility-Information-lighter, rgba(80, 193, 239, 0.1607843137));
}

.node-group {
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  margin-bottom: 0.375rem;
  border-bottom: 1px solid gray;
  align-items: flex-start;
  justify-content: center;
}

.suggestion-box {
  position: absolute;
  top: 2.375rem;
  right: 0.5rem;
  width: 60rem;
  z-index: 999;
}
.suggestion-box .card {
  box-shadow: 0 3px 15px 5px rgba(0, 0, 0, 0.14);
}