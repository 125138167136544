.search_box_container .search_box {
  width: 15rem;
  transition: background-color 0.15s ease-in;
  background-color: transparent;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.search_box_container .search_box.active {
  transition: background-color 0.15s ease-out;
  background-color: #f8f9fa;
}
.search_box_container .search_box .search {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  width: 100%;
  position: relative;
  display: flex;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  overflow: hidden;
  color: #495057;
}
.search_box_container .search_box .search .searchTerm {
  width: 100%;
  padding: 5px;
  outline: none;
  border: 0;
}
.search_box_container .search_box .search .searchButton {
  width: 2rem;
  background: #fff;
  text-align: center;
  cursor: default;
  font-size: 1rem;
  align-self: stretch;
  align-items: center;
  display: flex;
  justify-content: center;
}
.search_box_container .search_box .search.focussed {
  color: #495057;
  border-color: #3f8ce8;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(18, 78, 150, 0.25);
}
.search_box_container .search_box .search_box_results {
  position: absolute;
  background: #000;
  width: 300px;
}
.search_box_container .search_box .search_box-result-container {
  left: 0;
  position: absolute;
  width: 350px;
  color: #000;
  z-index: 100;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15), 0 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0 0.3rem 0.3rem 0.3rem;
  top: 100%;
  overflow: hidden;
}
.search_box_container .search_box .search_box-results-container {
  height: 15rem;
  overflow-y: auto;
  background-color: white;
  padding-top: 8px;
}
.search_box_container .search_box .search-results {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
}
.search_box_container .search_box .search-results li {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  word-wrap: break-word;
  overflow: hidden;
}
.search_box_container .search_box .search-results li a {
  display: block;
  padding: 4px 4px 4px 38px !important;
  color: #212529;
  text-decoration: none;
}
.search_box_container .search_box .search-results li a strong {
  display: block;
  padding: 3px 0 0;
  font-weight: 600;
  font-size: 1.1rem;
}
.search_box_container .search_box .search-results li a small {
  display: block;
  padding: 2px 0;
  font-weight: 400;
  font-size: 0.9rem;
}
.search_box_container .search_box .search-results a.active {
  background-color: #255c9e;
  color: #fff;
}
.search_box_container .search_box .search-results a.active:hover {
  background-color: #124e96;
  color: #fff;
}
.search_box_container .search_box .search-results li a .result-icon {
  position: absolute;
  left: 7px;
  top: 4px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  font-size: 24px;
}
.search_box_container .search_box .search-results .search-message {
  padding: 10px;
  color: #212529;
  font-weight: 400;
  font-size: 1.1rem;
}
.search_box_container .search_box .search-filters-button {
  position: relative;
  display: table-cell;
  height: 30px;
  width: 48px;
  font-weight: 400;
  font-size: 1rem;
  color: rgba(38, 41, 44, 0.64);
  padding-top: 10px;
  text-align: center;
  text-decoration: none;
}
.search_box_container .search_box .search-filters {
  padding-top: 0;
  border-bottom: 1px solid #dee2e6;
  height: 40px;
  display: block;
  padding-inline-start: 0;
  background-color: #f8f9fa;
}
.search_box_container .search_box .search-filters .search-filters-filter {
  transition: all 0.2s ease-in-out;
  display: block;
  float: left;
  height: 40px;
  min-width: 48px;
  padding: 0 5px;
}
.search_box_container .search_box .search-filters .search-filters-filter.active {
  border-bottom: 6px solid #d55e96;
}
.search_box_container .search_box .search-filters .search-filters-filter.active a {
  color: #212529;
}
.search_box_container .search_box .search-filters .search-filters-filter.active:hover {
  border-bottom-width: 7px;
  background-color: #e9ecef;
}
.search_box_container .search_box .search-filters .search-filters-filter a {
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  font-size: 1.2rem;
  text-decoration: none;
}
.search_box_container .search_box .search-filters .search-filters-button-text {
  font-weight: 600;
  font-size: 1.4rem;
}
.search_box_container .search_box .customFade {
  transition-property: opacity;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
  opacity: 0;
}
.search_box_container .search_box .customFade.customShow {
  opacity: 1;
}
.search_box_container .organisation-name {
  pointer-events: none;
  float: right;
  margin: 0.75rem 0.25rem;
}